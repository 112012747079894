import React from "react";
import { Box, ListItem, ListItemButton, ListItemIcon, List, Stack, ListItemText, } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarList } from "../../helper/Helper";
import Logo from '../../assets/images/Logo.png';
import SmallLogo from '../../assets/images/SmallLogo.png';
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const Sidebar = ({ handleDrawerToggle, drawerStatus, activeSubMenu, setActiveSubMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSubMenu = (name) => {
    if (activeSubMenu === name) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(name);
    }
  };

  const renderSubMenu = (subMenus) => {
    return subMenus.map((subMenu, index) => (
      <ListItem key={index} onClick={() => navigate(subMenu.path)} disablePadding>
        <ListItemButton onClick={handleDrawerToggle} sx={{ gap: 1, p: '11px', justifyContent: 'center', borderRadius: '6px', backgroundColor: `${location.pathname === subMenu.path ? 'white.main' : ''}`, background: location.pathname === subMenu.path ? 'primary.main !important' : 'transparent !important', }}
          className={location.pathname === subMenu.path ? 'active_sidebar' : ''}>
          <ListItemIcon sx={{ minWidth: 'unset', }}>{location.pathname === subMenu.path ? subMenu.activeIcon : subMenu.icon}</ListItemIcon>
          {drawerStatus &&
            <ListItemText primary={`${subMenu.name.slice(0, 15)}${subMenu.name.length > 15 ? '...' : ''}`} className='sidebar_text' sx={{
              margin: '0', transition: 'width 0.3s ease-in-out', textWrap: 'nowrap',
              color: `${location.pathname === subMenu.path ? 'black.main' : 'white.main'}`
            }} />}
        </ListItemButton>
      </ListItem>
    ));
  };

  const renderListItems = (sidebarItem) => {
    const { name, icon, activeIcon, path, SUBMENU, subMenus } = sidebarItem;
    return (
      <List sx={{ padding: '0', my: '5px', mx: 1 }} >
        <ListItem onClick={() => navigate(path)} disablePadding>
          <ListItemButton onClick={() => toggleSubMenu(name)} sx={{ gap: 1, p: '11px', justifyContent: 'center', borderRadius: '6px', backgroundColor: `${location.pathname === path ? 'white.main' : ''}`, background: location.pathname === path ? 'primary.main !important' : 'transparent !important', }} className={location.pathname === path ? 'active_sidebar' : ''}>
            <ListItemIcon sx={{ minWidth: 'unset', }}>{location.pathname === path ? activeIcon : icon}</ListItemIcon>
            {drawerStatus &&
              <ListItemText primary={`${name.slice(0, 15)}${name.length > 15 ? '...' : ''}`} className='sidebar_text' sx={{ margin: '0', color: `${location.pathname === path ? 'black.main' : 'white.main'}` }} />}
            {(drawerStatus && SUBMENU) && (activeSubMenu === name ? <IoIosArrowDown style={{ color: '#fff', fontSize: '14px' }} /> : <IoIosArrowForward style={{ color: '#fff', fontSize: '14px' }} />)}
          </ListItemButton>
        </ListItem>
        {SUBMENU && activeSubMenu === name &&
          <List sx={{ px: 1, py: 0 }}>
            {renderSubMenu(subMenus)}
          </List>
        }
      </List>
    );
  };

  return (

    <Box sx={{ transition: 'width 0.3s ease-in-out', height: '100vh' }}>
      <List sx={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }}>
        {drawerStatus ?
          <img src={Logo} style={{ width: '180px' }} alt='' /> :
          <img src={SmallLogo} style={{ width: '40px' }} alt='' />
        }
      </List>
      <Stack justifyContent={'space-between'} height={'calc(100% - 100px)'}>
        <Box marginTop={3}>
          {SidebarList.map((sidebarItem, index) => renderListItems(sidebarItem))}
        </Box>
      </Stack>
    </Box>
  )
}

export default Sidebar;
