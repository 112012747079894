import { Box, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import EditUserDetails from './EditUserDetails'
import { RiUser6Line, TbSocial, RiHistoryFill } from '../../helper/Icons';
import { Link } from 'react-router-dom';
import { HistoryInfo, SocialInfo, UserInfo } from './contactinfo';

const contactInfoList = [
  {
    name: 'userInfo',
    icon: <RiUser6Line style={{ fontSize: '20px' }} />,
  },
  {
    name: 'socialInfo',
    icon: <TbSocial style={{ fontSize: '20px' }} />,
  },
  {
    name: 'historyInfo',
    icon: <RiHistoryFill style={{ fontSize: '20px' }} />,
  },
]

const ContactInfo = () => {
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [tabName, setTabName] = useState('userInfo');

  const toggleUserDrawer = () => {
    setOpenUserDrawer(!openUserDrawer);
  };

  const renderComponent = () => {
    switch (tabName) {
      case 'userInfo':
        return <UserInfo />;
      case 'socialInfo':
        return <SocialInfo />;
      case 'historyInfo':
        return <HistoryInfo />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Stack p={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
          <RiUser6Line style={{ fontSize: '20px' }} />
          <Typography fontWeight={"600"} lineHeight={"16px"}>Contact info</Typography>
        </Stack>
        <Typography fontSize={"14px"}><Link onClick={toggleUserDrawer}>Edit</Link></Typography>
        <EditUserDetails openDrawer={openUserDrawer} toggleDrawer={toggleUserDrawer} />
      </Stack>
      <Divider />
      <Stack>
        <Stack py={2} px={1} flexDirection={'row'} gap={'10px'} alignItems={'center'}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50px', height: '50px', borderRadius: '5px', backgroundColor: 'secondary.main', fontSize: '20px', lineHeight: '22px', color: '#fff', fontWeight: '600' }}>W</Box>
          <Box>
            <Typography fontSize={"18px"} fontWeight={"600"}>John Wick</Typography>
            <Typography fontSize={"14px"} >Newark, New Jersey</Typography>
          </Box>
        </Stack>
        <Divider />
        <Stack flexDirection={'row'} >
          {contactInfoList.map((data, i) => {
            return (
              <Box key={i} onClick={() => { setTabName(`${data.name}`) }} sx={{ width: '100%', borderBottom: `${data.name === tabName ? '1px solid #ffc107' : '1px solid #ffc10740'}`, textAlign: 'center', py: 1, cursor: 'pointer' }}>
                {data.icon}
              </Box>
            )
          })}
        </Stack>
        {renderComponent()}
      </Stack>
    </Box>
  )
}

export default ContactInfo