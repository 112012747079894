import React from 'react'
import { Box, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton } from '@mui/material'
import { Button, Input } from '../../commons'
import { CloseIcon } from '../../../helper/Icons'

const Ban = ({ handleClose, modalOpen }) => {
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Ban
            </DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '400px' }}>
                    <Input labelinput='Why do you want to ban?' multiline rows={3} />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Ban IP" />
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName='Ban' size='small' onClick={handleClose} />
            </DialogActions>
        </>
    )
}

export default Ban