import { Box, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input, Modal } from '../commons'
import { } from '../../helper/Theme'
import { BiSolidPlusSquare, FiTrash2, MdOutlineEdit, TbNotes } from '../../helper/Icons';

const Notes = () => {

    const [createNote, setCreateNote] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        head: "",
        para: "",
    });

    const toggleShowNote = () => { setCreateNote(true) }
    const toggleHideNote = () => { setCreateNote(false) }

    return (
        <Box>
            <Stack p={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                    <TbNotes style={{ fontSize: '20px' }} />
                    <Typography fontWeight={"600"} lineHeight={"16px"}>Notes</Typography>
                </Stack>
                <BiSolidPlusSquare style={{ fontSize: '25px', cursor: 'pointer' }} onClick={toggleShowNote} />
            </Stack>
            <Divider />
            <Stack gap={2} py={2} px={1}>
                {createNote &&
                    <Stack gap={1}>
                        <Input placeholder='Enter your note here' multiline rows={2} />
                        <Stack flexDirection={'row'} gap={1} justifyContent={'flex-end'}>
                            <Button buttonName='Cancel' color='white' size='small' onClick={toggleHideNote} />
                            <Button buttonName='Save' size='small' onClick={toggleHideNote} />
                        </Stack>
                    </Stack>
                }
                <Stack gap={1}>
                    {[1, 1].map((data, i) => {
                        return (
                            <Box key={i}>
                                <Box sx={{ border: '1px solid #0000001f', p: '10px', borderRadius: '10px' }}>
                                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                        <Typography fontSize={'15px'} fontWeight={600}>Test</Typography>
                                        <Stack flexDirection={'row'} gap={'2px'}>
                                            <MdOutlineEdit style={{ fontSize: '16px', cursor: 'pointer' }} onClick={toggleShowNote} />
                                            <FiTrash2 style={{ fontSize: '16px', cursor: 'pointer' }} onClick={() =>
                                                setIsModalOpen({ open: true, currentComponent: "delete", head: "Delete Note", para: "Are you sure you want to delete this Note?" })} />
                                        </Stack>
                                    </Stack>
                                    <Stack>
                                        <Typography fontSize={'12px'}>Created by Nettyfy Technologies</Typography>
                                    </Stack>
                                </Box>
                                <Typography fontSize={'12px'} textAlign={'end'}>January 02 at 12:27 PM</Typography>
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "" })
                } />
        </Box>
    )
}

export default Notes