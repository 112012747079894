import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, FileUpload } from "../../commons";
import { createContactApiHandler } from "../../../Service/Contacts/Contacts.service";
import {
  errorNotification,
  successNotification,
} from "../../../helper/Notification";

const ImportContact = ({ handleClose, callBack }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteImage, setDeleteImage] = useState([]);

  const uploadContactHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("import_contacts_file", files[0]);

      const { data, message, success } = await createContactApiHandler(
        formData
      );
      if (success) {
        successNotification(message);
        handleClose();
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Import People
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: "600px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FileUpload
              files={files}
              FileType="File"
              acceptFormat=".xlsx, .xls,.csv"
              setFiles={setFiles}
              setDeleteImage={setDeleteImage}
              deleteImage={deleteImage}
              singleImage={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Import"
          size="small"
          disabled={files.length === 0 || loading}
          onClick={() => uploadContactHandler()}
        />
      </DialogActions>
    </>
  );
};

export default ImportContact;
