import { DELETE, POST, get, post, put } from "../../Web.request";

export const loginServiceApiHandler = (payload) => {
  console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
  return POST(`${process.env.REACT_APP_API_URL}/user_profile/login`, payload);
};

// get all User Data with out pagination
export const getAllUserApiData = async () => {
  return get(
    `${process.env.REACT_APP_API_URL}/user_profile/user_list_without_pagination`
  );
};

// get all visitor data with out pagination
export const getAllVisitorApiData = async () => {
  return get(
    `${process.env.REACT_APP_API_URL}/user_profile/visitor_user_create`
  );
};

// get all User Data with pagination
export const getAllUserApiDataWithPagination = async (search, page) => {
  return get(
    `${process.env.REACT_APP_API_URL}/user_profile/create_user?search=${search}&page=${page}`
  );
};

// delete User By Id
export const deleteUserByIdApiHandler = async (id) => {
  return DELETE(
    `${process.env.REACT_APP_API_URL}/user_profile/user_delete_by_admin/${id}`
  );
};


// create User 
export const createUserApiHandler = async (payload) => {
  return post(
    `${process.env.REACT_APP_API_URL}/user_profile/create_user`,
    payload
  );
};

// Update User Data By Admin
export const updateUserByAdminApiHandler = (id,payload) =>{
  return put(`${process.env.REACT_APP_API_URL}/user_profile/user_update_by_admin/${id}`,payload);
};