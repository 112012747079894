import React from 'react'
import { } from '../../helper/Theme';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Button } from '../commons';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { TbWorldWww } from "react-icons/tb";


const integrationData = [
  {
    icon: <FaFacebookF style={{ fontSize: '25px' }} />,
    name: 'Facebook Account',
    para: 'Connect Chatbot with your Facebook Developer Account.',
  },
  {
    icon: <FaInstagram style={{ fontSize: '25px' }} />,
    name: 'Instagram Account',
    para: 'Connect Chatbot with your Instagram Developer Account.',
  },
  {
    icon: <FaWhatsapp style={{ fontSize: '25px' }} />,
    name: 'Business Whatsapp',
    para: 'Connect Chatbot with your Business Whatsapp.',
  },
  {
    icon: <TbWorldWww style={{ fontSize: '25px' }} />,
    name: 'Website',
    para: 'Connect Chatbot with your Website.',
  },
]


const Integration = () => {

  return (
    <Box className='cardContainer' sx={{ p: { xs: 2, sm: 3, md: 5 } }}>
      <Grid container spacing={2}>
        {integrationData.map((item) => (
          <Grid item xs={6} key={item.id}>
            <Stack flexDirection={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'} sx={{ background: '#ffc10720', p: 2, borderRadius: '10px' }}>
              <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                <Box sx={{ background: '#fff', p: 1, borderRadius: '5px' }}>
                  {item.icon}
                </Box>
                <Box>
                  <Typography fontSize={'15px'} fontWeight={'500'}>{item.name}</Typography>
                  <Typography fontSize={'12px'} fontWeight={'400'}>{item.para}</Typography>
                </Box>
              </Stack>
              <Box>
                <Button buttonName='Connect' size='small' />
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Integration