import React from 'react'
import Chart from 'react-google-charts'

const CommonChart = (props) => {
    const { data, chartType, height, options } = props

    return (
        <Chart
            width={'100%'}
            height={height}
            loader={<div>Loading Chart</div>}
            chartType={chartType}
            data={data}
            options={options}
        />
    )
}

export default CommonChart
