import React from 'react'
import { Stack, Typography } from '@mui/material'
import { GrDocumentTime } from '../../../helper/Icons';

const HistoryInfo = () => {
    return (
        <>
            <Stack p={2} gap={2}>
                <Stack flexDirection={'row'} gap={2}>
                    <GrDocumentTime style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>Created</Typography>
                        <Typography fontSize={"16px"}>Jan 11, 2024 at 11:11 pm</Typography>
                    </Stack>
                </Stack>
                <Stack flexDirection={'row'} gap={2}>
                    <GrDocumentTime style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>Updated</Typography>
                        <Typography fontSize={"16px"}>Jan 11, 2024 at 11:11 pm</Typography>
                    </Stack>
                </Stack>
                <Stack flexDirection={'row'} gap={2}>
                    <GrDocumentTime style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>First Seen</Typography>
                        <Typography fontSize={"16px"}>Jan 11, 2024 at 11:11 pm</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default HistoryInfo