import React, { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { MdOutlineEditNotifications, FaRegUserCircle, MdOutlineIntegrationInstructions, TbHelpSquareRounded } from '../../helper/Icons'
import { Heading, Modal } from '../../components/commons';
import { Integration, Notification, Profile, Support } from '../../components/settings';

const conversationstabs = [
    {
        icon: <FaRegUserCircle />,
        name: 'Profile',
    },
    {
        icon: <MdOutlineEditNotifications />,
        name: 'Notification',
    },
    {
        icon: <MdOutlineIntegrationInstructions />,
        name: 'Integration',
    },
    {
        icon: <TbHelpSquareRounded />,
        name: 'Support',
    },
];

const Settings = () => {
    const [tabName, setTabName] = useState('Profile');
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
    });

    const renderComponent = () => {
        switch (tabName) {
            case 'Profile':
                return <Profile />;
            case 'Notification':
                return <Notification />;
            case 'Integration':
                return <Integration />;
            case 'Support':
                return <Support />;
            default:
                return null;
        }
    };

    return (
        <>
            <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={1}>
                <Heading head='Accounts Settings' />
            </Stack>
            <Stack flexDirection={'row'} gap={'10px'} mb={2} justifyContent={'space-between'} alignItems={'flex-end'}>
                <Stack flexDirection={'row'} gap={'10px'}>
                    {conversationstabs.map((data, i) => {
                        return (
                            <Box key={i} className={data.name === tabName ? 'active_tabs' : ''} sx={{ backgroundColor: 'background.main', border: '1px solid #0000001f', borderRadius: '10px', padding: '15px 20px', cursor: 'pointer' }}
                                onClick={() => { setTabName(`${data.name}`) }}>
                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                    {data?.icon}
                                    <Typography fontSize={'14px'} fontWeight={'600'}>{data?.name}</Typography>
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
            {renderComponent()}
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "" })
                }
            />
        </>
    )
}

export default Settings