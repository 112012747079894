import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button } from "..";
import { deleteUserByIdApiHandler } from "../../../Service/User/User.service";
import {
  errorNotification,
  successNotification,
} from "../../../helper/Notification";

const Delete = ({ handleClose, modalOpen, callBack }) => {
  const modalData = modalOpen.data;

  const agreeHandler = async () => {
    if (modalData.Module === "USER") {
      const { data, message, success } = await deleteUserByIdApiHandler(
        modalData.DeleteId
      );
      if (success) {
        successNotification(message);
        handleClose();
        callBack();
      } else {
        errorNotification(message);
      }
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.head}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: "400px" }}>
          <Typography fontSize={"18px"}>{modalOpen.para}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="No"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName="Yes" size="small" onClick={() => agreeHandler()} />
      </DialogActions>
    </>
  );
};

export default Delete;
