import React from 'react'
import { Stack, Typography } from '@mui/material'
import { SlSocialFacebook, SlSocialTwitter, SlSocialInstagram } from '../../../helper/Icons';

const SocialInfo = () => {
    return (
        <>
            <Stack p={2} gap={2}>
                <Stack flexDirection={'row'} gap={2}>
                    <SlSocialFacebook style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>facebook</Typography>
                        <Typography fontSize={"16px"}>URL</Typography>
                    </Stack>
                </Stack>
                <Stack flexDirection={'row'} gap={2}>
                    <SlSocialTwitter style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>Twitter</Typography>
                        <Typography fontSize={"16px"}>URL</Typography>
                    </Stack>
                </Stack>
                <Stack flexDirection={'row'} gap={2}>
                    <SlSocialInstagram style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>Instagram</Typography>
                        <Typography fontSize={"16px"}>URL</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default SocialInfo