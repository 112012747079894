import React, { useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Box, Stack, Divider } from '@mui/material';
import { Button, Modal } from '../../components/commons';
import { Pagination, Search } from '../../components/commons/Table';

const Knowledge = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  return (
    <Box className='cardContainer' >
      <Box p={'0 5px 10px'}>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Search />
          <Pagination />
        </Stack>
      </Box>
      <Divider />
      <Box p={'10px 5px'}>
        <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
          <Button buttonName='Upload file' color='white' size='small' onClick={() => setIsModalOpen({ open: true, currentComponent: "uploadKnowBase" })} />
        </Stack>
      </Box>
      <Divider />
      <TableContainer component={Paper} className='tableContainer'>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className='MuiRoleTable-root'>
              <TableCell>Data source</TableCell>
              <TableCell>Imported by</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Refresh</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className='MuiRoleTable-root'
              sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
              <TableCell component="th" scope="row">test.pdf</TableCell>
              <TableCell component="th" scope="row">Admin</TableCell>
              <TableCell component="th" scope="row">Just now</TableCell>
              <TableCell component="th" scope="row">-</TableCell>
              <TableCell component="th" scope="row">-</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </Box>
  )
}

export default Knowledge