import React, { useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Box, Stack, Divider } from '@mui/material';
import {  } from '../../helper/Theme';
import { LuTrash2, MdOutlineEdit, MdOutlineMoreVert } from '../../helper/Icons';
import { Button, Modal } from '../commons';
import { Pagination, Search } from '../commons/Table';
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

const Roles = () => {

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
    });

    return (
        <Box className='cardContainer' >
            <Box p={'0 5px 10px'}>
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Search />
                    <Pagination />
                </Stack>
            </Box>
            <Divider />
            <Box p={'10px 5px'}>
                <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                    {/* <Button variant="text" startIcon={<BsFilterRight />} buttonName='Filter' /> */}
                    <Button buttonName='Create Role' color='white' size='small' onClick={() => setIsModalOpen({ open: true, currentComponent: "addRole" })} />
                </Stack>
            </Box>
            <Divider />
            <TableContainer component={Paper} className='tableContainer'>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow className='MuiRoleTable-root'>
                            <TableCell>Sr No.</TableCell>
                            <TableCell>Role Name</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {[1, 1, 1, 1].map((data, i) => {
                            return ( */}
                        <TableRow className='MuiRoleTable-root'
                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                            <TableCell component="th" scope="row">1</TableCell>
                            <TableCell component="th" scope="row">Admin</TableCell>
                            <TableCell component="th" scope="row">
                                <Stack alignItems={'flex-end'}>
                                    <DropdownMenu.Root>
                                        <DropdownMenu.Trigger className='chatDropDown'>
                                            <MdOutlineMoreVert style={{ fontSize: '24px', cursor: 'pointer' }} />
                                        </DropdownMenu.Trigger>
                                        <DropdownMenu.Content className='chatDropDownContent' align='end'>
                                            <DropdownMenu.Item className='chatDropDownMenu' onClick={() =>
                                                setIsModalOpen({ open: true, currentComponent: "addRole", isEdit: true, })}>
                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><MdOutlineEdit /><Box>Edit</Box></Stack>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Item className='chatDropDownMenu' onClick={() =>
                                                setIsModalOpen({ open: true, currentComponent: "delete", head: "Delete Role", para: "Are you sure you want to Delete this Role?" })}>
                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><LuTrash2 /><Box>Delete</Box></Stack>
                                            </DropdownMenu.Item>

                                        </DropdownMenu.Content>
                                    </DropdownMenu.Root>
                                </Stack>
                            </TableCell>
                        </TableRow>
                        {/* )
                        })} */}
                        {/* <TableRow>
              <TableCell sx={{ color: "silver", textAlign: "center", paddingTop: "90px", borderBottom: "none", fontSize: "30px", }} colSpan="6">
                No records to display
              </TableCell>
            </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "" })
                }
            />
        </Box>
    )
}

export default Roles