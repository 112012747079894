export const loginIv = {
  email: "",
  password: "",
};

export const createTicketIv = {
  subject: "",
  message: "",
  assigned_to: "",
  visitor_user: "",
  attachment_files: [],
};

export const createContactsIV = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  organization: "",
  tags: "",
};

export const uploadContactsIv = {
  import_contacts_file: "",
};

export const createOrganizationIv = {
  org_name: "",
  org_email: "",
  org_phone: "",
  org_tags: "",
  org_web_url: "",
};

export const createUserIv = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  address: "",
  password: "",
  confirm_password: "",
  groups: "",
};
