import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import { BsFilterRight, RiOrganizationChart } from "../../helper/Icons";
import { Button, Modal } from "../commons";
import { Pagination, Search } from "../commons/Table";
import { useNavigate } from "react-router-dom";
import { getOrganizationListApiHandler } from "../../Service/Organization/Organization.service";
import TableSkeleton from "../commons/TableSkeleton";
import NoRecordFound from "../commons/NoRecordFound";
import moment from "moment";

const Organizations = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [organizationList, setOrganizationList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  useEffect(() => {
    getOrganizationListHandler(searchText, page);
  }, []);

  const getOrganizationListHandler = async (search, page) => {
    try {
      setLoading(true);
      const { count, data, message, permission, success, totalPage } =
        await getOrganizationListApiHandler(search, page);

      if (success) {
        setOrganizationList(data);
        setDataCount(count);
      } else {
        setOrganizationList([]);
        setDataCount(0);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const callBackHandler = () => {
    getOrganizationListHandler(searchText, page);
  };

  const pageChangeHandler = (value) => {
    setPage(value);
    getOrganizationListHandler(searchText, value);
  };

  const searchHandler = (value) => {
    setSearchText(value);
    getOrganizationListHandler(value, page);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        gap={1}
        alignItems={"center"}
        justifyContent={"flex-end"}
        mb={1}
      >
        <Button
          startIcon={<RiOrganizationChart />}
          buttonName="Create Organizations"
          size="small"
          color="white"
          onClick={() =>
            setIsModalOpen({
              open: true,
              currentComponent: "createOrganization",
            })
          }
        />
      </Stack>
      <Box className="cardContainer">
        <Box p={"0 5px 10px"}>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Search
              searchHandler={(event) => searchHandler(event.target.value)}
            />
            <Pagination
              pageNumber={page}
              count={dataCount}
              pageChangeHandler={pageChangeHandler}
            />
          </Stack>
        </Box>
        <Divider />
        <Box p={"10px 5px"}>
          <Button
            variant="text"
            size="small"
            startIcon={<BsFilterRight />}
            buttonName="Filter"
          />
        </Box>
        <Divider />
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Primary Email</TableCell>
                <TableCell>Primary Phone</TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>Website URL</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableSkeleton rowsNum={5} colsNum={7} />
              ) : organizationList && organizationList.length > 0 ? (
                organizationList.map((res, i) => {
                  const {
                    created_at,
                    id,
                    org_email,
                    org_name,
                    org_phone,
                    org_tags,
                    org_web_url,
                    updated_at,
                  } = res;
                  return (
                    <TableRow
                      key={i}
                      onClick={() => navigate("/contacts/details")}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {i + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {org_name ? org_name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {org_email ? org_email : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {org_phone ? org_phone : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row" width={"200px"}>
                        <Stack
                          sx={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "secondary.main",
                              padding: "5px 12px",
                              color: "black.main",
                              borderRadius: "5px",
                              width: "fit-content",
                              fontSize: "12px",
                            }}
                          >
                            {org_tags ? org_tags : "-"}
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {org_web_url ? org_web_url : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(updated_at).format("DD-MM-YYYY - HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <NoRecordFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        callBack={callBackHandler}
      />
    </>
  );
};

export default Organizations;
