import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "../../commons";
import { useFormik } from "formik";
import { createContactsIV } from "../../../helper/intialValues";
import { createContactsValidation } from "../../../helper/Validation";
import { createContactApiHandler } from "../../../Service/Contacts/Contacts.service";
import {
  errorNotification,
  successNotification,
} from "../../../helper/Notification";

const AddContact = ({ handleClose,callBack }) => {
  const [loading, setLoading] = useState(false);

  const createContactsHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("first_name", formik.values.first_name);
      formData.append("last_name", formik.values.last_name);
      formData.append("email", formik.values.email);
      formData.append("phone", formik.values.phone);
      formData.append("organization", formik.values.organization);
      formData.append("tags", formik.values.tags);
      const { data, message, success } = await createContactApiHandler(
        formData
      );
      if (success) {
        successNotification(message);
        handleClose();
        callBack();
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: createContactsIV,
    validationSchema: createContactsValidation,
    onSubmit: createContactsHandler,
  });
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        New Contact
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: "600px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input
              labelinput="First Name"
              formik={formik}
              name="first_name"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              labelinput="Last Name"
              formik={formik}
              name="last_name"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              labelinput="Email"
              formik={formik}
              name="email"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              labelinput="Phone number"
              formik={formik}
              name="phone"
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              labelinput="Organization Name"
              formik={formik}
              name="organization"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              labelinput="Tags"
              formik={formik}
              name="tags"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Create"
          size="small"
          onClick={() => formik.handleSubmit()}
        />
      </DialogActions>
    </>
  );
};

export default AddContact;
