import { ThemeProvider } from '@mui/material';
import { Route, Routes } from "react-router-dom";
import { theme } from './helper/Theme';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from './helper/ScrollToTop';
import Layout from './components/layouts/Layout';
import Login from './pages/login';
import Signup from './pages/signup';
import Forgotpassword from './pages/forgotpassword';
import Changepassword from './pages/changepassword';
import Dashboard from './pages/dashboard';
import Conversations from './pages/conversations';
import ConversationsDetails from './pages/conversationdetails';
import Livesupport from './pages/livesupport';
import Contacts from './pages/contacts';
import Report from './pages/report';
import Usermanagement from './pages/usermanagement';
import Settings from './pages/settings';
import Intent from './pages/Intent';
import Knowledge from './pages/Knowledge';
import ContactDetails from './pages/contactdetails/ContactDetails';
import './App.css';

const pages = [
  { name: 'Dashboard', path: 'dashboard', element: <Dashboard /> },
  { name: 'Conversations', path: 'conversations', element: <Conversations /> },
  { name: 'ConversationsDetails', path: 'conversations/details', element: <ConversationsDetails /> },
  { name: 'LiveSupport', path: 'livesupport', element: <Livesupport /> },
  { name: 'LiveSupportDetails', path: 'livesupport/details', element: <ConversationsDetails /> },
  { name: 'Contacts', path: 'contacts', element: <Contacts /> },
  { name: 'ContactDetails', path: 'contacts/details', element: <ContactDetails /> },
  { name: 'Report', path: 'report', element: <Report /> },
  { name: 'UserManagement', path: 'usermanagement', element: <Usermanagement /> },
  { name: 'Settings', path: 'settings', element: <Settings /> },
  { name: 'intent', path: 'intent', element: <Intent /> },
  { name: 'knowledge', path: 'knowledge', element: <Knowledge /> },
]

function App() {

  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <div className='background_Image'>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<Forgotpassword />} />
            <Route path="/change-password" element={<Changepassword />} />
            {pages.map((page, index) => {
              const { name, path, element } = page;
              return (
                <Route key={index} exact path={`/${path}`} name={name} element={<Layout>{element}</Layout>} />
              );
            })}
          </Routes>
          <ToastContainer />
        </div>
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default App;
