import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Box, Checkbox, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, Input } from '../../commons'
import {  } from '../../../helper/Theme'

const moduleList = [
    {
        name: 'Conversations',
        create: <Checkbox />,
        read: <Checkbox />,
        edit: <Checkbox />,
        delete: <Checkbox />,
    },
    {
        name: 'Contacts',
        create: <Checkbox />,
        read: <Checkbox />,
        edit: <Checkbox />,
        delete: <Checkbox />,
    },
    {
        name: 'Chatbot Management',
        create: <Checkbox />,
        read: <Checkbox />,
        edit: <Checkbox />,
        delete: <Checkbox />,
    },
    {
        name: 'Visitor Analytics',
        create: <Checkbox />,
        read: <Checkbox />,
        edit: <Checkbox />,
        delete: <Checkbox />,
    },
    {
        name: 'User Management',
        create: <Checkbox />,
        read: <Checkbox />,
        edit: <Checkbox />,
        delete: <Checkbox />,
    },
    {
        name: 'Help & Support',
        create: <Checkbox />,
        read: <Checkbox />,
        edit: <Checkbox />,
        delete: <Checkbox />,
    },
    {
        name: 'Report',
        create: <Checkbox />,
        read: <Checkbox />,
        edit: <Checkbox />,
        delete: <Checkbox />,
    },
]

const AddRoles = ({ handleClose, modalOpen }) => {


    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : 'Create'}  Role
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2} maxWidth={{ sm: '900px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Input labelinput='Role Name' />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={'12px'} fontWeight={600} mb={'2px'}>Role Management</Typography>
                        <Box className='cardContainer' >
                            <TableContainer component={Paper} className='tableContainer'>
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Module Name</TableCell>
                                            <TableCell colSpan={4} align='center'>Permission</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align='center'>Create</TableCell>
                                            <TableCell align='center'>Read</TableCell>
                                            <TableCell align='center'>Edit</TableCell>
                                            <TableCell align='center'>Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {moduleList.map((data, i) => {
                                            return (
                                                <TableRow role="checkbox" key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                                    <TableCell component="th" scope="row" >{data.name}</TableCell>
                                                    <TableCell component="th" scope="row" align='center'>{data.create}</TableCell>
                                                    <TableCell component="th" scope="row" align='center'>{data.read}</TableCell>
                                                    <TableCell component="th" scope="row" align='center'>{data.edit}</TableCell>
                                                    <TableCell component="th" scope="row" align='center'>{data.delete}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Save' : 'Create'} size='small' onClick={handleClose} />
            </DialogActions>
        </>
    )
}

export default AddRoles