import * as React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  IoTicketOutline,
  MdOutlineMoreVert,
  RiSpam3Line,
  TbDatabasePlus,
} from "../../helper/Icons";
import { } from "../../helper/Theme";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Input, Modal, Select } from "../commons";
import { useLocation } from "react-router-dom";

const chatData = [
  {
    index: 0,
    message: {
      sender: "user",
      text: "Hello, how can I help you?",
    },
  },
  {
    index: 1,
    message: {
      sender: "system",
      text: "I'm here to assist you with your inquiries.",
    },
  },
];

const Chats = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = React.useState({
    open: false,
    currentComponent: "",
    head: "",
    para: "",
  });
  const [leadValue, setLeadValue] = React.useState("1");
  const handleChange = (e) => {
    setLeadValue(e.target.value);
  };

  const router = ["/conversations/details", "/contacts/details"];
  const isCurrentLocationInRouter = router.includes(location.pathname);

  const leadList = [
    {
      name: (
        <Stack flexDirection={"row"} gap={"6px"} alignItems={"center"}>
          <Box
            sx={{
              width: "15px",
              height: "15px",
              background: "orange",
              borderRadius: "3px",
            }}
          ></Box>
          <Box sx={{ fontSize: "14px" }}>Open</Box>
        </Stack>
      ),
      value: "1",
    },
    {
      name: (
        <Stack flexDirection={"row"} gap={"6px"} alignItems={"center"}>
          <Box
            sx={{
              width: "15px",
              height: "15px",
              background: "#d9d9d9",
              borderRadius: "3px",
            }}
          ></Box>
          <Box sx={{ fontSize: "14px" }}>Close</Box>
        </Stack>
      ),
      value: "2",
    },
  ];

  return (
    <Box
      className="cardContainer"
      sx={{
        boxShadow: "unset !important",
        height: "100%",
        padding: "0 !important",
      }}
    >
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={"12px 16px"}
      >
        <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              borderRadius: "5px",
              backgroundColor: "secondary.main",
              fontSize: "14px",
              lineHeight: "16px",
              color: "#fff",
              fontWeight: "600",
            }}
          >
            J
          </Box>
          <Box>
            <Typography fontSize={"15px"} fontWeight={"600"}>
              John Wick
            </Typography>
          </Box>
        </Stack>
        <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
          <Select
            selectList={leadList}
            value={leadValue}
            onChange={handleChange}
            className="leadSelect"
          />
          <DropdownMenu.Root>
            <DropdownMenu.Trigger className="chatDropDown">
              <MdOutlineMoreVert
                style={{ fontSize: "24px", cursor: "pointer" }}
              />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className="chatDropDownContent" align="end">
              <DropdownMenu.Item
                className="chatDropDownMenu"
                onClick={() =>
                  setIsModalOpen({
                    open: true,
                    currentComponent: "composeTicket",
                    head: "",
                    para: "",
                  })
                }
              >
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                  <IoTicketOutline />
                  <Box>Create Ticket</Box>
                </Stack>
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className="chatDropDownMenu"
                onClick={() =>
                  setIsModalOpen({ open: true, currentComponent: "ban" })
                }
              >
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                  <RiSpam3Line />
                  <Box>Ban</Box>
                </Stack>
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className="chatDropDownMenu"
                onClick={() =>
                  setIsModalOpen({
                    open: true,
                    currentComponent: "delete",
                    head: "Achieved User",
                    para: "Are you sure you want to Achieved this User?",
                  })
                }
              >
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                  <TbDatabasePlus />
                  <Box>Achieved</Box>
                </Stack>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Stack>
      </Stack>
      <Divider />
      <Box
        p={2}
        sx={{
          height: "calc(100% - 60px)",
          overflowY: "auto",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Stack gap={1} justifyContent={"flex-start"} height={"100%"}>
          {chatData.map(({ index, message }) => {
            const isUser = message.sender === "user";
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: isUser ? "flex-end" : "flex-start",
                  gap: 1,
                }}
              >
                {isUser ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                      backgroundColor: "secondary.main",
                      fontSize: "14px",
                      lineHeight: "15px",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  >
                    C
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                      backgroundColor: "secondary.main",
                      fontSize: "14px",
                      lineHeight: "15px",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  >
                    U
                  </Box>
                )}
                <Box
                  sx={{
                    bgcolor: isUser ? "#f9f9f9" : "#ffc10730",
                    borderRadius: 1,
                    // borderTopLeftRadius: isUser ? 2 : 0,
                    // borderTopRightRadius: isUser ? 0 : 2,
                    border: "1px solid #dedede",
                    maxWidth: "80%",
                    p: 1,
                    fontSize: "0.875rem",
                  }}
                >
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: message.text }}
                    sx={{ color: "#24292f" }}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        {!isCurrentLocationInRouter && (
          <Box sx={{ position: "relative" }}>
            <Input />
            <Box className="chat_send_btn">
              {/* <SiSendinblue style={{ color: "#fff", fontSize: "20px" }} /> */}
            </Box>
          </Box>
        )}
      </Box>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            head: "",
            para: "",
          })
        }
      />
    </Box>
  );
};

export default Chats;
