import React, { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { GrFormEdit, RiOrganizationChart, HiOutlineUserGroup, RiSpam3Line, TbDatabasePlus } from '../../helper/Icons'
import { Button, Heading, Modal } from '../../components/commons';
import { Roles, Users } from '../../components/usermanagement';

const conversationstabs = [
    {
        icon: <HiOutlineUserGroup />,
        name: 'Users',
    },
    {
        icon: <RiOrganizationChart />,
        name: 'Roles',
    },
];

const UserManagement = () => {
    const [tabName, setTabName] = useState('Users');
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
    });

    const renderComponent = () => {
        switch (tabName) {
            case 'Users':
                return <Users />;
            case 'Roles':
                return <Roles />;
            default:
                return null;
        }
    };

    return (
        <>
            <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={1}>
                <Heading head='User Management' />
            </Stack>
            <Stack flexDirection={'row'} gap={'10px'} mb={2} justifyContent={'space-between'} alignItems={'flex-end'}>
                <Stack flexDirection={'row'} gap={'10px'}>
                    {conversationstabs.map((data, i) => {
                        return (
                            <Box key={i} className={data.name === tabName ? 'active_tabs' : ''} sx={{ backgroundColor: 'background.main', border: '1px solid #0000001f', borderRadius: '10px', padding: '15px 20px', cursor: 'pointer' }}
                                onClick={() => { setTabName(`${data.name}`) }}>
                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                    {data?.icon}
                                    <Typography fontSize={'14px'} fontWeight={'600'}>{data?.name}</Typography>
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
            {renderComponent()}
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "" })
                }
            />
        </>
    )
}

export default UserManagement