import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { ChangePassword, ComposeTicket, CreateOrganization, Delete, Logout, Ban } from './modals';
import { AddRoles, AddUsers } from '../usermanagement/modals';
import { AddContact, ImportContact } from '../contacts/modals';
import CreateIntent from '../../pages/Intent/CreateIntent';
import UploadKnowBase from '../../pages/Knowledge/UploadKnowBase';

export default function CommonModal({ handleClose, modalOpen, callBack }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
    >
      {modalOpen.currentComponent === 'changePassword' && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}
      {modalOpen.currentComponent === 'logout' && (
        <Logout handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}
      {modalOpen.currentComponent === 'delete' && (
        <Delete handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}

      {modalOpen.currentComponent === 'composeTicket' && (
        <ComposeTicket handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}
      {modalOpen.currentComponent === 'createOrganization' && (
        <CreateOrganization handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}
      {modalOpen.currentComponent === 'ban' && (
        <Ban handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}

      {modalOpen.currentComponent === 'addContact' && (
        <AddContact handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}
      {modalOpen.currentComponent === 'importContact' && (
        <ImportContact handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}

      {modalOpen.currentComponent === 'addUser' && (
        <AddUsers handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}
      {modalOpen.currentComponent === 'addRole' && (
        <AddRoles handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}

      {modalOpen.currentComponent === 'createIntent' && (
        <CreateIntent handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}
      {modalOpen.currentComponent === 'uploadKnowBase' && (
        <UploadKnowBase handleClose={handleClose} modalOpen={modalOpen} callBack={callBack} />
      )}

    </Dialog>
  );
}
