import React, { useState } from 'react';
import { Box, Drawer } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';

export default function Layout({ children }) {
    const { window } = Window;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [drawerWidth, setDrawerWidth] = React.useState(240);
    const [drawerStatus, setDrawerStatus] = React.useState(true);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const handleDrawerOpenToggleWeb = () => {
        setDrawerWidth(240);
        setDrawerStatus(true)
    };

    const handleDrawerToggleWeb = () => {
        if (drawerWidth === 240) {
            setDrawerWidth(60);
            setDrawerStatus(false)
        } else {
            setDrawerWidth(240);
            setDrawerStatus(true)
        }
        setActiveSubMenu(null);
    };


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ height: '100vh', width: '100vw' }}>
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Box component="nav" onMouseEnter={handleDrawerOpenToggleWeb}
                    sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }, transition: 'width 0.2s ease-in-out' }}>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', transition: 'width 0.2s ease-in-out', width: drawerWidth, backgroundColor: 'black.main' },
                        }}
                    >
                        <Sidebar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} drawerStatus={drawerStatus} activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
                    </Drawer>
                    <Drawer variant="permanent" sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', transition: 'width 0.2s ease-in-out', width: drawerWidth, backgroundColor: 'black.main' },
                    }}>
                        <Sidebar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} drawerStatus={drawerStatus} activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
                    </Drawer>
                </Box>
                <Box component="main" sx={{ flexGrow: 1, overflow: 'auto', width: '-webkit-fill-available', }} >
                    <Box sx={{ justifyContent: 'space-between', alignItems: 'center', width: '-webkit-fill-available', backgroundColor: 'transparent', p: { xs: '10px', sm: '10px 20px' } }}>
                        <Header handleDrawerToggle={handleDrawerToggle} handleDrawerToggleWeb={handleDrawerToggleWeb} />
                    </Box>
                    <Box component="main" sx={{ flexGrow: 1, backgroundColor: 'transparent', p: { xs: '10px', sm: '20px' } }}>
                        {children}
                    </Box>
                </Box>
            </Box>
        </Box>

    );
}
