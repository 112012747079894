import React, { useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { } from '../../helper/Theme';
import { Button, Input, Modal, UploadImage } from '../commons';
import UploadPic from '../commons/UploadPic';

const Profile = () => {

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        head: '', para: ''
    });
    return (
        <>
            <Stack flexDirection={'row'} gap={1} justifyContent={'flex-end'} mb={1}>
                <Button buttonName='Change Password' size='small' color='white' onClick={() => setIsModalOpen({ open: true, currentComponent: "changePassword" })} />
                <Button buttonName='Delete Account' size='small' color='white' onClick={() => setIsModalOpen({ open: true, currentComponent: "delete", head: 'Delete Account', para: 'Are you sure want to delete your account?' })} />
            </Stack>
            <Box className='cardContainer' sx={{ p: { xs: 2, sm: 3, md: 5 } }}>
                <Grid container spacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 3, md: 5 }} maxWidth={'1000px'}>
                    <Grid item xs={12}>
                        {/* <UploadImage /> */}
                        <UploadPic />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='First Name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Last Name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Email' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Organization' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Contact Number' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Password' passwordinput />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection={'row'} gap={{ xs: 1 }} mt={2}>
                            <Button buttonName='Save The Changes' size='small' />
                            <Button buttonName='Cancel' color='white' size='small' />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", head: '', para: '' })
                }
            />
        </>
    )
}

export default Profile