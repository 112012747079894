import React from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { SignupImage } from '../../helper/Constant'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Input } from '../../components/commons'
import { FcGoogle, FaFacebookF } from '../../helper/Icons'

const Signup = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ position: 'relative', width: { xs: '-webkit-fill-available', md: '100%' }, height: '-webkit-fill-available', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack flexDirection={{ md: 'row' }} alignItems={'center'} justifyContent={'space-around'} sx={{ width: '100%', height: 'fit-content' }}>
                <Box width={{ xs: '94%', md: 'initial' }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Stack gap={{ xs: 4 }} width={{ xs: '100%', sm: '450px' }}>
                        <Box>
                            <Typography fontSize={{ xs: '28px', sm: '38px' }} fontWeight={"600"} mb={'3px'}>Sign Up</Typography>
                            <Typography fontSize={{ xs: '16px', sm: '22px' }}>Already Have Account? <Link to={'/'}>Sign In</Link></Typography>
                        </Box>
                        <Stack gap={{ xs: 2, sm: 2 }}>
                            <Input placeholder='Enter First Name' />
                            <Input placeholder='Enter Last Name' />
                            <Input placeholder='Enter Email' />
                            <Input placeholder='Enter Password' passwordinput />
                            <Box width={'100%'} mt={{ xs: 2, sm: 3 }}>
                                <Button buttonName='Sign Up' onClick={() => navigate('/')} style={{ width: '100%' }} />
                            </Box>
                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} justifyContent={'center'} my={{ xs: 1, sm: 2 }}>
                                <Divider sx={{ borderColor: '#465B7A', borderWidth: 'revert', width: '42%' }} />
                                <Typography fontSize={'20px'} fontWeight={"600"} sx={{ textAlign: 'center' }}>or</Typography>
                                <Divider sx={{ borderColor: '#465B7A', borderWidth: 'revert', width: '42%' }} />
                            </Stack>
                            <Stack flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'center'}>
                                <FcGoogle style={{ fontSize: '28px', cursor: 'pointer' }} />
                                <FaFacebookF style={{ fontSize: '28px', cursor: 'pointer', color: '#3C5A9A' }} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
                <Box width={'initial'} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Stack>
                        <img src={SignupImage} alt='login_banner_image' style={{ width: '480px' }} />
                    </Stack>
                </Box>
            </Stack >
        </Box >
    )
}

export default Signup