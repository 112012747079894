import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { NewPassImage } from '../../helper/Constant'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '../../components/commons'

const ChangePassword = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ position: 'relative', width: { xs: '-webkit-fill-available', md: '100%' }, height: '-webkit-fill-available', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack flexDirection={{ md: 'row' }} alignItems={'center'} justifyContent={'space-around'} sx={{ width: '100%', height: 'fit-content' }}>
                <Box width={{ xs: '94%', md: 'initial' }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Stack gap={{ xs: 4 }} width={{ xs: '100%', sm: '450px' }}>
                        <Box>
                            <Typography fontSize={{ xs: '28px', sm: '38px' }} fontWeight={"600"} mb={'3px'}>Create New Password</Typography>
                        </Box>
                        <Stack gap={{ xs: 2, sm: 2 }}>
                            <Input placeholder='Enter New Password' passwordinput />
                            <Input placeholder='Enter Confirm password' passwordinput />
                            <Box width={'100%'} mt={{ xs: 2, sm: 3 }}>
                                <Button buttonName='Continue' onClick={() => navigate('/')} style={{ width: '100%' }} />
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
                <Box width={'initial'} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Stack>
                        <img src={NewPassImage} alt='login_banner_image' style={{ width: '480px' }} />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default ChangePassword