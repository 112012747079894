import React from 'react'
import {
    Paper,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Stack,
    Divider,
  } from "@mui/material";
  
function NoRecordFound() {
  return (
    <TableRow>
    <TableCell
      sx={{
        color: "silver",
        textAlign: "center",
        paddingTop: "90px",
        borderBottom: "none",
        fontSize: "30px",
      }}
      colSpan="6"
    >
      No records to display
    </TableCell>
  </TableRow>
  )
}

export default NoRecordFound
