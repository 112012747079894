import * as React from 'react';
import { styled } from '@mui/material/styles';
import { FiSearch } from "react-icons/fi";
import { InputBase } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: '#fff',
  padding: '9px 15px',
  border: '1px solid #0000001f',
  // boxShadow: '2px 2px 25px 0px #00000026',
  '&:hover': {
    backgroundColor: '#fff',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 10,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#2B2B2B',
  width: '100%',
  '& .MuiInputBase-input': {
    fontSize: '12px',
    borderLeft: '2px solid #000 !important',
    padding: '2px 8px',
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(2)}) !important`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
    },
    [theme.breakpoints.up('lg')]: {
      width: '30ch',
    },
  },
}));

const CommonSearch = ({ inputValue, setInputValue, setHovered, searchHandler }) => {
  return (
    <Search>
      <StyledInputBase
        placeholder="Search here..."
        inputProps={{ 'aria-label': 'search' }}
        onChange={searchHandler}
      />
      <SearchIconWrapper>
        <FiSearch style={{ fontSize: '18px', color: '#A4A4A4' }} />
      </SearchIconWrapper>
    </Search>
  )
}

export default CommonSearch