import * as Yup from "yup";

export const LoginValidation = Yup.object({
    email: Yup.string()
      .trim()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Invalid email format"
      )
      .required("Email is required"),
    password: Yup.string().trim().required("Password is required"),
});

export const createTicketValidation = Yup.object({
  subject : Yup.string().trim().required("Subject is required"),
  message: Yup.string().trim().required("Message is required"),
  assigned_to: Yup.string().trim().required("Assigned to is required"),
  visitor_user: Yup.string().trim().required("Visitor is required"),
});

export const createContactsValidation = Yup.object({
  first_name : Yup.string().trim().required("First name is required"),
  last_name: Yup.string().trim().required("Last name is required"),
  email: Yup.string()
  .trim()
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    "Invalid email format"
  )
  .required("Email is required"),
  phone: Yup.string()
  .trim()
  .matches(/^\d+$/, "Phone number must contain only numbers")
  .length(10, "Phone number must be 10 digits")
  .required("Phone number is required"),
  organization: Yup.string().trim().required("Organization is required"),
  tags: Yup.string().trim().required("Tags is required"),
});

export const uploadContactsValidation = Yup.object({
  import_contacts_file: Yup.string().required("File is required"),
});



export const createOrganizationValidation = Yup.object({
  org_name: Yup.string().trim().required("Organization name is required"),
  org_email: Yup.string()
  .trim()
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    "Invalid email format"
  )
  .required("Email is required"),
  org_phone: Yup.string()
  .trim()
  .matches(/^\d+$/, "Phone number must contain only numbers")
  .length(10, "Phone number must be 10 digits")
  .required("Phone number is required"),
  org_tags: Yup.string().trim().required("Tags is required"),
  org_web_url: Yup.string()
  .trim()
  .matches(
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
    "Invalid website URL format"
  )
  .required("Web URL is required"),
});


export const createUserValidation = Yup.object({
  email: Yup.string()
  .trim()
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    "Invalid email format"
  )
  .required("Email is required"),
  first_name : Yup.string().trim().required("First name is required"),
  last_name: Yup.string().trim().required("Last name is required"),
  phone: Yup.string()
  .trim()
  .matches(/^\d+$/, "Phone number must contain only numbers")
  .length(10, "Phone number must be 10 digits")
  .required("Phone number is required"),
  address : Yup.string().trim().required("Address is required"),
  password: Yup.string()
  .required("Password is required")
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{6,16}$/,
    "Password must be 6-16 characters long and contain at least one number, one uppercase letter, one lowercase letter, and one special character (!@#$%^&*()-_=+{};:,<.>)."
  ),
  confirm_password: Yup.string()
  .trim()
  .required("Confirm password is required")
  .oneOf([Yup.ref("password"), null], "Passwords must match"),
  groups: Yup.string().trim().required("Role is required"),
});

export const updateUserValidation = Yup.object({
  email: Yup.string()
  .trim()
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    "Invalid email format"
  )
  .required("Email is required"),
  first_name : Yup.string().trim().required("First name is required"),
  last_name: Yup.string().trim().required("Last name is required"),
  phone: Yup.string()
  .trim()
  .matches(/^\d+$/, "Phone number must contain only numbers")
  .length(10, "Phone number must be 10 digits")
  .required("Phone number is required"),
  address : Yup.string().trim().required("Address is required"),
  groups: Yup.string().trim().required("Role is required"),
})