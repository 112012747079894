import React, { useState } from 'react';
import { Box, Grid, Menu, Stack, Typography } from '@mui/material';
import { CgMenuLeft, IoNotificationsOutline, IoSettingsOutline, PiBellSimpleRinging } from '../../helper/Icons';
import { SidebarList } from '../../helper/Helper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { UserAvtar } from '../../helper/Constant';
import { Search } from '../commons/Table';
import { MenuButton } from '../commons';
import Cookies from "universal-cookie";

const Header = ({ handleDrawerToggle, handleDrawerToggleWeb }) => {
  const location = useLocation();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const isEdit = location?.state?.isEdit || false;
  const isView = location?.state?.isView || false;
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleNotificationClick = () => { setIsNotificationOpen(true) };
  const handleAlertClick = () => { setIsAlertOpen(true) };


  const userDetails = cookies.get("userData");

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '6px', sm: 1 }} justifyContent={'flex-start'}>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MenuButton onClick={handleDrawerToggle} icon={<CgMenuLeft className='menu_icon' style={{ color: '#000' }} />} />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <MenuButton onClick={handleDrawerToggleWeb} icon={<CgMenuLeft className='menu_icon' style={{ color: '#000' }} />} />
          </Box>
          <Stack flexDirection={'row'} gap={1} justifyContent={'center'} alignItems={'center'}>
            <img src={userDetails?.profilePicture ? userDetails?.profilePicture : UserAvtar} alt='user-avatar' width={'50px'} height={'50px'} style={{ objectFit: "cover", borderRadius: "50%" }} />
            <Stack sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Typography fontSize={'14px'} lineHeight={1.2}>Hello,</Typography>
              <Typography fontSize={'20px'} fontWeight={"600"} lineHeight={1.2}>{userDetails?.firstName ? userDetails?.firstName : 'John Wick'}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box>
          <Stack flexDirection={'row'} gap={{ xs: '6px', sm: '12px' }} justifyContent={'center'} alignItems={'center'}>
            <Search />
            <DropdownMenu.Root open={isAlertOpen} onOpenChange={(open) => setIsAlertOpen(open)}>
              <DropdownMenu.Trigger className='notificationDropDownTrigger'>
                <MenuButton icon={<IoNotificationsOutline style={{ fontSize: '22px', strokeWidth: '2.2px', color: '#353535' }} />} />
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className='notificationDropDownContent' align='end' onClick={handleAlertClick}>
                <DropdownMenu.Item className='notificationDropDownMenu' onClick={handleAlertClick}>
                  <Box sx={{ width: '350px', height: '300px' }}>
                    <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={'10px'}>
                      <Typography fontSize={'15px'} fontWeight={600}>All Alert</Typography>
                      <Typography fontSize={'13px'} fontWeight={400}><Link>Mark all as read</Link></Typography>
                    </Stack>
                    <Box maxHeight={'calc(100% - 30px)'} height={'100%'} sx={{ overflow: 'hidden' }}>
                      <Stack gap={1} sx={{ height: '-webkit-fill-available', overflow: 'scroll' }}>
                        {[1, 1, 1, 1, 1].map((data, i) => {
                          return (
                            <Stack key={i} flexDirection={'row'} gap={1} alignItems={'center'} sx={{ background: '#eef4f6', padding: 1 }}>
                              <Box>
                                <PiBellSimpleRinging style={{ fontSize: '25px' }} />
                              </Box>
                              <Box>
                                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={'3px'}>
                                  <Typography fontSize={'14px'} fontWeight={600}>New email update!</Typography>
                                  <Typography fontSize={'12px'} fontWeight={400}>3 min ago</Typography>
                                </Stack>
                                <Typography fontSize={'12px'} fontWeight={400} color={'#a1a1a1'}>Now, anyone receiving your ticketing emails will see a simplified return address see...</Typography>
                              </Box>
                            </Stack>
                          )
                        })}
                      </Stack>
                    </Box>
                  </Box>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            {/* <MenuButton icon={<IoSettingsOutline style={{ fontSize: '22px', strokeWidth: '2.2px', color: '#353535', cursor: 'pointer' }} />} onClick={() => navigate('/settings')} /> */}
          </Stack>
        </Box>
      </Stack>
    </>
  )
}

export default Header