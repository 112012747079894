import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { } from '../../helper/Theme';
import { Chats, ContactInfo, Notes, Organization, Tags } from '../../components/chatdetails';
import { BackButton, Button, Heading, Modal } from '../../components/commons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { IoTicketOutline } from 'react-icons/io5';
import { BsBrowserChrome } from "react-icons/bs";
import { FaWindows } from "react-icons/fa6";
import { BiTimer } from "react-icons/bi";

const timelineData = [
  {
    head: 'Web Session',
    para: (
      <>
        Device <BsBrowserChrome /> <FaWindows /> 2405:201:2001:f0e6:f0ec:48d9:2154:5c8b
      </>
    ),
    para2: 'Location Ahmedabad, India',
    date: 'January 02 at 06:58 PM',
    background: '#ffc107',
    color: '',
  },
  {
    head: 'Web Session',
    para: (
      <>
        Device <BsBrowserChrome /> <FaWindows /> 2405:201:2001:f0e6:f0ec:48d9:2154:5c8b
      </>
    ),
    para2: 'Location Ahmedabad, India',
    date: 'January 02 at 06:58 PM',
    background: '#ffc107',
    color: '',
  },
]


const ContactDetails = () => {

  const [isModalOpen, setIsModalOpen] = React.useState({
    open: false,
    currentComponent: "",
  });

  return (
    <>
      {/* <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={1}>
          <BackButton />
          <Heading head='Contact Details' />
      </Stack> */}
      <Box height={"calc(100vh - 0)"} display={"flex"} sx={{ overflow: "hidden" }}>
        <Box width={"100%"} height={"100%"} sx={{ overflow: "auto" }}>
          <Box height={'-webkit-fill-available'} sx={{ overflow: 'hidden' }}>
            <Stack flexDirection={'row'} height={'-webkit-fill-available'} gap={3}>
              <Box className='cardContainer' width={'30%'} sx={{ overflowY: 'auto' }}>
                <Stack gap={'10px'}>
                  <ContactInfo />
                  <Organization />
                  <Notes />
                  <Tags />
                </Stack>
              </Box>
              <Box width={'70%'}>
                <Stack alignItems={'flex-end'}>
                  <Button buttonName="Create Tickets" size='small' color='white' onClick={() =>
                    setIsModalOpen({ open: true, currentComponent: "composeTicket", head: "", para: "", })} />
                </Stack>
                <Stack mb={2}>
                  <Heading head='Timeline' />
                </Stack>
                <Box>
                  <VerticalTimeline layout='1-column-left'>
                    {timelineData.map((data, i) => {
                      return (
                        <VerticalTimelineElement
                          key={i}
                          className="vertical-timeline-element--work"
                          contentStyle={{ background: '#ffc10730', color: '#000' }}
                          contentArrowStyle={{ borderRight: '7px solid  #ffc10730' }}
                          iconStyle={{ background: '#ffc107', color: '#fff' }}
                          icon={<BiTimer />}
                          position='right'
                          date={data.date}
                        >
                          <Typography className='timeline_head'>{data.head}</Typography>
                          <Typography className='timeline_para'>{data.para}</Typography>
                          <Typography className='timeline_para'>{data.para2}</Typography>
                        </VerticalTimelineElement>
                      )
                    })}
                  </VerticalTimeline>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", head: "", para: "", })
        } />
    </>
  )
}

export default ContactDetails