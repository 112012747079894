import React, { useEffect, useState } from "react";
import { Box, Checkbox, Divider, Grid, Stack, Typography } from "@mui/material";
import { LoginImage } from "../../helper/Constant";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "../../components/commons";
import { FcGoogle, FaFacebookF } from "../../helper/Icons";
import { useFormik } from "formik";
import { loginIv } from "../../helper/intialValues";
import Cookies from "universal-cookie";
import { LoginValidation } from "../../helper/Validation";
import { loginServiceApiHandler } from "../../Service/User/User.service";
import { errorNotification, successNotification } from "../../helper/Notification";

const Login = () => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const cookies = new Cookies();

  const userLoginHandler = async() =>{
    try{
      setLoading(true);
      const {data,message,success} = await loginServiceApiHandler(formik.values);
    if(success){
      successNotification(message);
      cookies.set("USERTOKEN", data.token);
      const userDetails ={
        profilePicture : data.profile_image ? data.profile_image : null,
        firstName: data.first_name
      }
      cookies.set("userData", userDetails);
      if (rememberMe) {
        cookies.set("userEmail", formik.values.email);
        cookies.set("userPassword", formik.values.password);
      } else {
        cookies.remove("userEmail");
        cookies.remove("userPassword");
      }
      navigate(`/dashboard`);
    }else{
      errorNotification(message);
    } 
    }catch(err){
      console.log(err);
    }finally{
      setLoading(false);
    }
  };

  

  const handleRemeberMeChange = (event) =>{
    setRememberMe(event.target.checked)
  }

  useEffect(()=>{
    const userEmail = cookies.get("userEmail");
    const userPassword = cookies.get("userPassword");
    if(userEmail && userPassword){
      setRememberMe(true);
      navigate(`/dashboard`);
      formik.setValues({
        ...formik.values,
        email: userEmail,
        password: userPassword,
      });
    }
  },[]);

  
  const formik = useFormik({
    initialValues:loginIv,
    validationSchema:LoginValidation,
    onSubmit:userLoginHandler
  })

  console.log("login",formik.values);

  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: "-webkit-fill-available", md: "100%" },
        height: "-webkit-fill-available",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        flexDirection={{ md: "row" }}
        alignItems={"center"}
        justifyContent={"space-around"}
        sx={{ width: "100%", height: "fit-content" }}
      >
        <Box
          width={{ xs: "94%", md: "initial" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack gap={{ xs: 4 }} width={{ xs: "100%", sm: "450px" }}>
            <Box>
              <Typography
                fontSize={{ xs: "28px", sm: "38px" }}
                fontWeight={"600"}
                mb={"3px"}
              >
                Sign In
              </Typography>
              <Typography fontSize={{ xs: "16px", sm: "22px" }}>
                Don't have an account? <Link to={"/signup"}>Sign Up</Link>
              </Typography>
            </Box>
            <Stack gap={{ xs: 2, sm: 2 }}>
              <Input placeholder="Enter Email" name="email" formik={formik} inputProps={{ maxLength: 50 }} />
              <Input placeholder="Enter Password" passwordinput  name="password" formik={formik} inputProps={{ maxLength: 50 }} />
              <Grid
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: { xs: 1, sm: 2 },
                }}
              >
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item>
                      <Checkbox
                        checked={rememberMe}
                        onChange={handleRemeberMeChange}
                        color="primary"
                        sx={{
                          padding: "0",
                          mr: "5px",
                          "& .MuiSvgIcon-root": { fontSize: { sm: 26 } },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography fontSize={{ sm: "18px" }}>
                        Remember me
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "end",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Link to={"/forgot-password"}>Forgot Password?</Link>
                </Grid>
              </Grid>
              <Box width={"100%"}>
                <Button
                  buttonName="Sign In"
                  loading={loading}
                  disabled={loading}
                  onClick={() => formik.handleSubmit()}
                  style={{ width: "100%" }}
                />
              </Box>
              <Stack
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
                justifyContent={"center"}
                my={{ xs: 1, sm: 2 }}
              >
                <Divider
                  sx={{
                    borderColor: "#465B7A",
                    borderWidth: "revert",
                    width: "42%",
                  }}
                />
                <Typography
                  fontSize={"20px"}
                  fontWeight={"600"}
                  sx={{ textAlign: "center" }}
                >
                  or
                </Typography>
                <Divider
                  sx={{
                    borderColor: "#465B7A",
                    borderWidth: "revert",
                    width: "42%",
                  }}
                />
              </Stack>
              <Stack
                flexDirection={"row"}
                gap={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <FcGoogle style={{ fontSize: "28px", cursor: "pointer" }} />
                <FaFacebookF
                  style={{
                    fontSize: "28px",
                    cursor: "pointer",
                    color: "#3C5A9A",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box width={"initial"} sx={{ display: { xs: "none", md: "block" } }}>
          <Stack>
            <img
              src={LoginImage}
              alt="login_banner_image"
              style={{ width: "480px" }}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default Login;
