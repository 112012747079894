import {post,get} from "../../Web.request";

// to create Organization API
export const createOrganizationApiHandler = (payload) =>{
    return post(`${process.env.REACT_APP_API_URL}/contact_us/create_organization`,payload);
};


// to get list of organizations API
export const getOrganizationListApiHandler = (search,page) =>{
    return get(`${process.env.REACT_APP_API_URL}/contact_us/create_organization?search=${search}&page=${page}`);
};
