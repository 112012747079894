import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  GrFormEdit,
  IoTicketOutline,
  MdOutlineChatBubbleOutline,
  RiSpam3Line,
  TbDatabasePlus,
} from "../../helper/Icons";
import { Archived, Chats, Spam, Tickets } from "../../components/conversations";
import { Button, Heading, Modal } from "../../components/commons";

const conversationstabs = [
  {
    icon: <MdOutlineChatBubbleOutline />,
    name: "Chats",
  },
  {
    icon: <IoTicketOutline />,
    name: "Tickets",
  },
  {
    icon: <TbDatabasePlus />,
    name: "Archived",
  },
  {
    icon: <RiSpam3Line />,
    name: "Spam",
  },
];

const Conversations = () => {
  const [tabName, setTabName] = useState("Chats");
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const renderComponent = () => {
    switch (tabName) {
      case "Chats":
        return <Chats />;
      case "Tickets":
        return <Tickets />;
      case "Archived":
        return <Archived />;
      case "Spam":
        return <Spam />;
      default:
        return null;
    }
  };

  const callBack = () => {
    console.log("DOne");
  };

  return (
    <>
      <Stack flexDirection={"row"} gap={1} alignItems={"center"} mb={1}>
        <Heading head="Conversation" />
      </Stack>
      <Stack
        flexDirection={"row"}
        gap={"10px"}
        mb={2}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Stack flexDirection={"row"} gap={"10px"}>
          {conversationstabs.map((data, i) => {
            return (
              <Box
                key={i}
                className={data.name === tabName ? "active_tabs" : ""}
                sx={{
                  backgroundColor: "background.main",
                  border: "1px solid #0000001f",
                  borderRadius: "10px",
                  padding: "15px 20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setTabName(`${data.name}`);
                }}
              >
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                  {data?.icon}
                  <Typography fontSize={"14px"} fontWeight={"600"}>
                    {data?.name}
                  </Typography>
                </Stack>
              </Box>
            );
          })}
        </Stack>
        <Button
          startIcon={<GrFormEdit />}
          color="white"
          buttonName="Compose Ticket"
          size="small"
          onClick={() => {
            setIsModalOpen({ open: true, currentComponent: "composeTicket" });
          }}
        />
      </Stack>
      {renderComponent()}
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", })
        }
        callBack={callBack}
      />
    </>
  );
};

export default Conversations;
