import React from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton } from '@mui/material';
import { CloseIcon } from '../../helper/Icons'
import { Button, Input } from '../commons';

const EditUserDetails = ({ openDrawer, toggleDrawer }) => {
    const drawerWidth = 250;
    return (
        <>
            <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer} sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2 }, }} >
                <Stack height={"calc(100% - 0px)"} justifyContent={'space-between'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography fontWeight={600} fontSize={'15px'}>Edit Details </Typography>
                            <IconButton aria-label="close" onClick={toggleDrawer}
                                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} marginY={1}>
                                <Divider sx={{ borderColor: "gray.main", }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Input labelinput='First Name' />
                            </Grid>
                            <Grid item xs={12}>
                                <Input labelinput='Last Name' />
                            </Grid>
                            <Grid item xs={12}>
                                <Input labelinput='Email' />
                            </Grid>
                            <Grid item xs={12}>
                                <Input labelinput='Phone No' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Stack flexDirection={'row'} gap={1} justifyContent={'flex-end'}>
                        <Button buttonName='Cancel' color='white' size='small' onClick={toggleDrawer} style={{ width: '100%' }} />
                        <Button buttonName='Update' size='small' onClick={toggleDrawer} style={{ width: '100%' }} />
                    </Stack>
                </Stack>
            </Drawer>
        </>
    )
}

export default EditUserDetails