import React, { useState } from 'react'
import { Button, Heading, Modal } from '../../components/commons'
import { Box, Stack, Typography } from '@mui/material'
import {  } from '../../helper/Theme'
import { useNavigate } from 'react-router-dom'

const LiveSupport = () => {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        head: '',
        para: ''
    });

    return (
        <>
            <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={1}>
                <Heading head='Live Support' />
            </Stack>
            <Stack gap={'10px'}>
                {[1, 1, 1, 1].map((data, i) => {
                    return (
                        <Box key={i} className='cardContainer'>
                            <Stack flexDirection={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
                                <Stack flexDirection={'row'} gap={1}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '45px', height: '45px', borderRadius: '5px', backgroundColor: 'secondary.main', fontSize: '20px', lineHeight: '22px', color: '#fff', fontWeight: '600' }}>W</Box>
                                    <Box>
                                        <Typography fontSize={"18px"} fontWeight={"600"}>John Wick</Typography>
                                        <Typography fontSize={"14px"}>Hello I'm looking for job in frontend developer.</Typography>
                                    </Box>
                                </Stack>
                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                    <Button buttonName='Accept Chat' size='small' onClick={() => navigate('/livesupport/details')} />
                                    <Button buttonName='Reject Chat' size='small' color='white' onClick={() =>
                                        setIsModalOpen({ open: true, currentComponent: "delete", head: "Reject Chat", para: "Are you sure you want to Reject this Chat?" })} />
                                </Stack>
                            </Stack>
                        </Box>
                    )
                })}
            </Stack>
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "" })
                }
            />
        </>
    )
}

export default LiveSupport