import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Button, Input } from '../../components/commons'
import { CloseIcon } from '../../helper/Icons'
import { TiPlus } from "react-icons/ti";
import { TiMinus } from "react-icons/ti";
import { PiMagicWandBold } from "react-icons/pi";


const CreateIntent = ({ handleClose, modalOpen }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Create intent
      </DialogTitle>
      <IconButton aria-label="close" onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: '400px' }}>
          <Stack gap={2}>
            <Stack>
              <Input labelinput='Name' />
            </Stack>
            <Stack>
              <Input labelinput='Description' multiline rows={2} />
            </Stack>
            <Divider />
            <Stack gap={'10px'}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontWeight={'600'}>Utterances</Typography>
                <TiPlus style={{ fontSize: '20px', cursor: 'pointer' }} />
              </Stack>
              <Stack gap={1}>
                {[1, 1, 1, 1, 1].map(() => {
                  return (
                    <Stack justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'}>
                      <Typography fontSize={'13px'} fontWeight={'500'} sx={{ py: '3px' }}>We are all going to the movies tonight</Typography>
                      <TiMinus style={{ fontSize: '20px', cursor: 'pointer' }} />
                    </Stack>
                  )
                })}
              </Stack>
              <Stack sx={{ mt: 1 }}>
                <Button buttonName='Generate' style={{ width: '100%' }} startIcon={<PiMagicWandBold />} size='small' onClick={handleClose} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection={'row'} gap={1}>
          <Button buttonName='Cancel' color='white' size='small' onClick={handleClose} />
          <Button buttonName='Create' size='small' onClick={handleClose} />
        </Stack>
      </DialogActions>
    </>
  )
}

export default CreateIntent