import {post,get} from "../../Web.request";

// to create Contact 
export const createContactApiHandler = (payload) =>{
    return post(`${process.env.REACT_APP_API_URL}/contact_us/create_contact_us`,payload)
}

// to get list of contacts 
export const getContactListApiHandler = (search,page) =>{
    return get(`${process.env.REACT_APP_API_URL}/contact_us/create_contact_us?search=${search}&page=${page}`)
}