import React from 'react'
import { Box, Stack } from '@mui/material'
import {  } from '../../helper/Theme';
import { Chats, ContactInfo, Notes, Organization, Tags } from '../../components/chatdetails';
import { BackButton, Heading } from '../../components/commons';

const ConversationsDetails = () => {
	

	return (
		<>
			<Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={1}>
				<BackButton />
				<Heading head='User Details' />
			</Stack>
			<Box height={"calc(100vh - 150px)"} display={"flex"} sx={{ overflow: "hidden" }}>
				<Box width={"100%"} height={"100%"} sx={{ overflow: "auto" }}>
					<Box height={'-webkit-fill-available'} sx={{ overflow: 'hidden' }}>
						<Stack flexDirection={'row'} height={'-webkit-fill-available'} gap={1}>
							<Box width={'70%'}>
								<Chats />
							</Box>
							<Box className='cardContainer' width={'30%'} sx={{ overflowY: 'auto' }}>
								<Stack gap={'10px'}>
									<ContactInfo />
									<Organization />
									<Notes />
									<Tags />
								</Stack>
							</Box>
						</Stack>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default ConversationsDetails