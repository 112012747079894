import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import {} from "../../helper/Theme";
import { BsFilterRight, LuContact2, LuImport } from "../../helper/Icons";
import { Button, Modal } from "../commons";
import { Pagination, Search } from "../commons/Table";
import { useNavigate } from "react-router-dom";
import { getContactListApiHandler } from "../../Service/Contacts/Contacts.service";
import TableSkeleton from "../commons/TableSkeleton";
import NoRecordFound from "../commons/NoRecordFound";

const Peoples = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [contactList, setContactList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  useEffect(() => {
    getContactListHandler(searchText, page);
  }, []);

  const callBackhandler = () =>{
    getContactListHandler(searchText, page);
  };

  const getContactListHandler = async (search, Text) => {
    try {
      setLoading(true);
      const { data, message, success, count, permission, totalPage } =
        await getContactListApiHandler(search, Text);
      if (success) {
        setContactList(data);
        setDataCount(count);
      } else {
        setDataCount(0);
        setContactList([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const pageChangeHandler = (value) => {
    setPage(value);
    getContactListHandler(searchText, value);
  };

  const searchHandler = (value) => {
    setSearchText(value);
    getContactListHandler(value, page);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        gap={1}
        alignItems={"center"}
        justifyContent={"flex-end"}
        mb={1}
      >
        <Button
          startIcon={<LuContact2 />}
          buttonName="Add Contacts"
          size="small"
          color="white"
          onClick={() =>
            setIsModalOpen({ open: true, currentComponent: "addContact" })
          }
        />
        <Button
          startIcon={<LuImport />}
          buttonName="Import Contacts"
          size="small"
          color="white"
          onClick={() =>
            setIsModalOpen({ open: true, currentComponent: "importContact" })
          }
        />
      </Stack>
      <Box className="cardContainer">
        <Box p={"0 5px 10px"}>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Search
              searchHandler={(event) => searchHandler(event.target.value)}
            />
            <Pagination
              pageNumber={page}
              count={dataCount}
              pageChangeHandler={pageChangeHandler}
            />
          </Stack>
        </Box>
        <Divider />
        <Box p={"10px 5px"}>
          <Button
            variant="text"
            size="small"
            startIcon={<BsFilterRight />}
            buttonName="Filter"
          />
        </Box>
        <Divider />
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr No.</TableCell>
                <TableCell> First Name</TableCell>
                <TableCell> Last Name</TableCell>
                <TableCell> Phone </TableCell>
                <TableCell> Organization </TableCell>
                <TableCell> Email </TableCell>
                <TableCell>Tags</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableSkeleton rowsNum={5} colsNum={7} />
              ) : contactList && contactList.length > 0 ? (
                contactList.map((res, i) => {
                  const {
                    created_at,
                    email,
                    first_name,
                    id,
                    last_name,
                    organization,
                    phone,
                    tags,
                    updated_at,
                  } = res;
                  return (
                    <TableRow
                      key={i}
                      onClick={() => navigate("/contacts/details")}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {i + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {first_name ? first_name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {last_name ? last_name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {phone ? phone : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {organization ? organization : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {email ? email : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row" width={"200px"}>
                        <Stack
                          sx={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "secondary.main",
                              padding: "5px 12px",
                              color: "black.main",
                              borderRadius: "5px",
                              width: "-webkit-fill-available",
                              fontSize: "12px",
                            }}
                          >
                            {tags ? tags : '-'}
                          </Box>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <NoRecordFound/>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        callBack={callBackhandler}
      />
    </>
  );
};

export default Peoples;
