import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Input } from '../commons'
import {  } from '../../helper/Theme';
import { TbTags } from '../../helper/Icons';

const Tags = () => {

    const [selectedTags, setSelectedTags] = useState(['tag 1', 'tag 2']);

    const handleDeleteTags = (index) => {
        selectedTags.splice(index, 1);
        const neItems = [...selectedTags];
        setSelectedTags(neItems);
    }

    const handelTags = (e) => {
        if (e.code === "Enter") {
            selectedTags.push(e.target.value);
            const neItems = [...selectedTags];
            setSelectedTags(neItems);
            e.target.value = ""
        }
    }
    return (
        <Box >
            <Stack p={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                    <TbTags style={{ fontSize: '20px' }} />
                    <Typography fontWeight={"600"} lineHeight={"16px"}>Tags</Typography>
                </Stack>
            </Stack>
            <Divider />
            <Stack py={2} px={1}>
                <Stack>
                    <Input fullWidth placeholder="Enter Tags then press Enter" onKeyUpCapture={handelTags} type='text' />
                </Stack>
                <Stack flexDirection={'row'} flexWrap={'wrap'} gap={'5px'} mt={2}>
                    {
                        selectedTags.map((item, index) => {
                            return (
                                <Chip key={item} label={item} variant="outlined" onDelete={() => handleDeleteTags(index)} />
                            )
                        })
                    }
                </Stack>
            </Stack>
        </Box>
    )
}

export default Tags