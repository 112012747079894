import React, { useState, useCallback } from "react";
import {
  Button,
  Box,
  Grid,
  Paper,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { errorNotification } from "../../helper/Notification";

function FileUpload({
  files,
  setFiles,
  FileType,
  setDeleteImage,
  acceptFormat,
  deleteImage,
  singleImage,
}) {
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (event) => {
    if (event.target.files.length === 0) {
      return;
    }

    if (event.target.files && event.target.files[0].size > 2 * 1024 * 1024) {
      errorNotification(
        "Oops! It seems like the file you selected is too large. Please choose a file that is smaller than 2MB."
      );
    }

    if (singleImage) {
      setFiles([event.target.files[0]]);
    } else {
      setFiles([...files, event.target.files[0]]);
    }
  };

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);
      const droppedFiles = Array.from(event.dataTransfer.files);
      if (droppedFiles.length) {
        if (FileType === "File") {
          setFiles(droppedFiles);
        } else {
          if (singleImage) {
            setFiles([event.target.files[0]]);
          } else {
            setFiles([...files, ...droppedFiles]);
          }
        }
      }
    },
    [files]
  );

  const handleRemoveFile = (index, file) => {
    if (file?.id && !singleImage) {
      setDeleteImage([...deleteImage, file.id]);
    }
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleUploadClick = () => {
    console.log("Files to upload:", files);
    // Upload logic goes here
  };

  return (
    <Box>
      <Typography fontSize={"12px"} fontWeight={600} mb={"2px"}>
        Attachments
      </Typography>
      <label
        htmlFor="raised-button-file"
        onClick={handleUploadClick}
        style={{ width: "100%" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "70px",
            border: "1px dashed #c4c4c4",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: dragging ? "#e0e0e0" : "#f8f8f8",
            fontSize: "14px",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          Drop your files here or click to browse
          <input
            accept={acceptFormat}
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
        </Box>
      </label>

      {/* Thumbnails */}
      {console.log("fielessssssss", files)}
      {FileType === "File" ? (
        files &&
        files.length > 0 && (
          <>
            <h3> {files[0].name}</h3>
            <div onClick={() => handleRemoveFile(0, files)}>
              <h3>Delete</h3>
            </div>
          </>
        )
      ) : (
        <Grid container spacing={"10px"} style={{ marginTop: "0px" }}>
          {files &&
            files.length > 0 &&
            files?.map((file, index) => (
              <Grid item xs={singleImage ? 12 : 2.4} key={index}>
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: "100px",
                    position: "relative",
                    border: "1px solid #e3e3e3",
                    borderRadius: "10px",
                  }}
                >
                  <IconButton
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: "4",
                    }}
                    onClick={() => handleRemoveFile(index, file)}
                  >
                    <DeleteIcon sx={{ fontSize: "18px", color: "#fff" }} />
                  </IconButton>
                  <Box className="background_gradient"></Box>
                  {singleImage && singleImage === true ? (
                    file != undefined && (
                      <img
                        src={file?.image || URL.createObjectURL(file)} // Use file?.image if available, otherwise create a URL
                        alt={`preview ${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                    )
                  ) : (
                    // Render based on singleImage condition being false
                    // Check if file and file.id exist before rendering
                    <>
                      {file && file.id ? (
                        // Render image using file.image if available
                        <img
                          src={file.image}
                          alt={`preview ${index}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        // Render image using URL.createObjectURL(file) if file exists
                        file !== undefined && (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`preview ${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                              objectFit: "cover",
                            }}
                          />
                        )
                      )}
                    </>
                  )}

                  {singleImage && singleImage === true ? (
                    file != undefined && (
                      <img
                        src={file?.image || URL.createObjectURL(file)} // Use file?.image if available, otherwise create a URL
                        alt={`preview ${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                    )
                  ) : (
                    // Render based on singleImage condition being false
                    // Check if file and file.id exist before rendering
                    <>
                      {file && file.id ? (
                        // Render image using file.image if available
                        <img
                          src={file.image}
                          alt={`preview ${index}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        // Render image using URL.createObjectURL(file) if file exists
                        file !== undefined && (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`preview ${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                              objectFit: "cover",
                            }}
                          />
                        )
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
}

export default FileUpload;
