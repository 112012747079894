import React from 'react'
import { Box, Chip, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { CommonChart } from '../../components/commons/Chart'
import { getChatStatus, getUserLiveStatus, } from '../../helper/Theme';
import { toptrendsData, liveChatData, liveChatOptions, toptrendsOptions } from '../../helper/Helper';
import { CiStar } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { TotalChats, PageViews, TotalVisitors, SatisfiedVisitors, UserAvtar } from '../../helper/Constant';
import { CounterCard } from '../../components/dashboard';
import { Heading } from '../../components/commons';

const counterData = [
  {
    name: 'Total Visitors',
    img: TotalVisitors,
    per: '4%',
    count: '30K',
  },
  {
    name: 'Total Chats',
    img: TotalChats,
    per: '1%',
    count: '25K',
  },
  {
    name: 'Page Views',
    img: PageViews,
    per: '4%',
    count: '30K',
  },
  {
    name: 'Satisfied Visitors',
    img: SatisfiedVisitors,
    per: '2%',
    count: '23K',
  },
]

const Dashboard = () => {

  const navigate = useNavigate();
  var status = getChatStatus(true);
  var userStatus = getUserLiveStatus(false);

  return (
    <>
      <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={1}>
        <Heading head='Dashboard' />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            {
              counterData.map((data, i) => {
                return (
                  <Grid item xs={3} key={i}>
                    <CounterCard counterData={data} />
                  </Grid>
                )
              })
            }
            <Grid item xs={12}>
              <Box className='cardContainer' sx={{ p: 2 }}>
                <Heading smallHead={'Live Visitor'} />
                <CommonChart chartType="ColumnChart" height='300px' options={liveChatOptions} data={liveChatData} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className='cardContainer' sx={{ p: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '18px', fontWeight: 600 }}>History</Typography>
                <TableContainer component={Paper} className='tableContainer'>
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr No.</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell>Total Messages</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Agents</TableCell>
                        <TableCell>Rating</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Updated</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[1, 1, 1, 1].map((data, i) => {
                        return (
                          <TableRow key={i} onClick={() => navigate('/conversations/details')}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                            <TableCell component="th" scope="row">{i + 1}</TableCell>
                            <TableCell component="th" scope="row">
                              <Stack>
                                <Typography fontSize={'13px'} fontWeight={'500'}>John Doe</Typography>
                                <Typography fontSize={'11px'} variant='primary' fontWeight={'400'}>johndoe@gmail.com</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row">1</TableCell>
                            <TableCell component="th" scope="row">1 seconds</TableCell>
                            <TableCell component="th" scope="row">Anna</TableCell>
                            <TableCell component="th" scope="row">
                              <Stack flexDirection={'row'} gap={'3px'} alignItems={'center'} justifyContent={'center'}>
                                <div>4.5</div>
                                <CiStar style={{ fontSize: '18px' }} />
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" onClick={(e) => { e.stopPropagation() }}>
                              <Chip label={status?.title} sx={status?.styles} />
                            </TableCell>
                            <TableCell component="th" scope="row">Jun 29, 2022 at <br />01:14 PM</TableCell>
                          </TableRow>
                        )
                      })}
                      {/* <TableRow>
                            <TableCell sx={{ color: "silver", textAlign: "center", paddingTop: "90px", borderBottom: "none", fontSize: "30px", }} colSpan="6">
                                No records to display
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className='cardContainer' sx={{ p: 2 }}>
                <Heading smallHead={'Trending Topics'} />
                <CommonChart chartType="LineChart" height='320px' options={toptrendsOptions} data={toptrendsData} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className='cardContainer' sx={{ p: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '18px', fontWeight: 600 }}>List of Users</Typography>
                <TableContainer component={Paper} className='tableContainer'>
                  <Table stickyHeader aria-label="simple table">
                    <TableBody>
                      {[1, 1, 1, 1, 1, 1].map((data, i) => {
                        return (
                          <TableRow key={i} onClick={() => navigate('/conversations/details')}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                            <TableCell component="th" scope="row">
                              <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
                                <img src={UserAvtar} alt='user-avatar' width={'45px'} />
                                <Stack>
                                  <Typography fontSize={'15px'} fontWeight={'500'}>Hanna Montez</Typography>
                                  <Typography fontSize={'13px'} variant='primary' fontWeight={'400'}>Hanna123@mail.com</Typography>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" onClick={(e) => { e.stopPropagation() }}>
                              <Chip label={userStatus?.title} sx={userStatus?.styles} />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}

export default Dashboard