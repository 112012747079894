import React from 'react'
import { Heading } from '../../components/commons'
import { Box, CircularProgress, Stack, Typography, circularProgressClasses } from '@mui/material'
import { } from '../../helper/Theme'
import { CommonChart } from '../../components/commons/Chart'
import { chatData, chatOptions, ticketData, ticketOptions } from '../../helper/Helper'


const Report = () => {

    const progressValue = 56;
    return (
        <>
            <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={1}>
                <Heading head='Report & Analytics' />
            </Stack>
            <Stack gap={2} height={'calc(100vh - 150px)'}>
                <Stack flexDirection={'row'} gap={2} height={'100%'}>
                    <Box className='cardContainer' sx={{ width: '60%', p: 2 }}>
                        <Heading smallHead='Chats' />
                        <CommonChart chartType="LineChart" height='100%' options={chatOptions} data={chatData} />
                    </Box>
                    <Box className='cardContainer' sx={{ width: '40%', p: 2 }}>
                        <Heading smallHead='Performance' />
                        <Stack gap={1} height={'calc(100% - 30px)'} justifyContent={'center'} alignItems={'center'}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "relative",
                                    width: "fit-content",
                                }}
                            >
                                <Box sx={{ position: "relative", height: "100%" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        sx={{ color: '#ffc10750' }}
                                        size={200}
                                        thickness={5}
                                        value={100}
                                    />
                                    <CircularProgress
                                        variant="determinate"
                                        disableShrink
                                        sx={{
                                            color: "#ffc107",
                                            position: "absolute",
                                            left: 0,
                                            borderRadius: "50%",
                                            [`& .${circularProgressClasses.circle}`]: {
                                                strokeLinecap: "round",
                                            },
                                        }}
                                        size={200}
                                        thickness={5}
                                        value={progressValue}
                                    />
                                </Box>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    fontSize={"20px"}
                                    fontWeight={"600"}
                                    lineHeight={1}
                                    sx={{
                                        position: "absolute",
                                        left: "0",
                                        right: "0",
                                        textAlign: "center",
                                    }}
                                >
                                    {`${Math.round(progressValue)}%`}<br />
                                    <span style={{ fontSize: '10px' }}>Chatbot Performance</span>
                                </Typography>
                            </div>
                        </Stack>
                    </Box>
                </Stack>
                <Stack flexDirection={'row'} gap={2} height={'100%'}>
                    <Box className='cardContainer' sx={{ width: '60%', p: 2 }}>
                        <Heading smallHead='Tickets' />
                        <CommonChart chartType="ColumnChart" height='100%' options={ticketOptions} data={ticketData} />
                    </Box>
                    <Box className='cardContainer' sx={{ width: '40%', p: 2 }}>
                        <Heading smallHead='Trending Topics' />
                        <Stack gap={1} height={'calc(100% - 30px)'} justifyContent={'center'}>
                            {[1, 1, 1, 1, 1].map((data, i) => {
                                return (
                                    <Stack key={i} flexDirection={'row'} gap={2} justifyContent={'space-between'} sx={{ background: '#ffc10720', p: '5px 10px', borderRadius: '10px' }}>
                                        <Typography fontSize={'14px'} fontWeight={'600'}>{i + 1}. Topic {i + 1}</Typography>
                                        <Typography fontSize={'14px'} fontWeight={'400'}>10%</Typography>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </>
    )
}

export default Report