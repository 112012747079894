import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import {  } from '../../helper/Theme';
import { IoMdArrowRoundUp } from '../../helper/Icons';

const CounterCard = ({ counterData }) => {
    const { name, img, per, count } = counterData

    return (
        <Box className='cardContainer' p={'10px 20px !important'}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Typography fontSize={'15px'} fontWeight={'500'}>{name}</Typography>
                <img src={img} alt={name} />
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                <Typography fontSize={'28px'} fontWeight={'600'}>{count}</Typography>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={'5px'}>
                    <IoMdArrowRoundUp style={{ color: '#00B074', background: '#00B07430', fontSize: '16px', borderRadius: '50%', padding: '3px' }} />
                    <Typography fontSize={'12px'} fontWeight={'500'} color={'#999'}>{per}</Typography>
                </Stack>
            </Stack>
        </Box>
    )
}

export default CounterCard