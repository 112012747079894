import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Typography,
  Chip,
  Tooltip,
  Divider,
} from "@mui/material";
import { getChatStatus } from "../../helper/Theme";
import { BsFilterRight } from "../../helper/Icons";
import { Pagination, Search } from "../commons/Table";
import { useNavigate } from "react-router-dom";
import { Button } from "../commons";
import { getTicketDataApiHandler } from "../../Service/Ticket/Ticket.service";
import moment from "moment";
import TableSkeleton from "../commons/TableSkeleton";

const Tickets = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [ticketList, setTicketList] = useState([]);

  var status = getChatStatus(true);

  useEffect(() => {
    getTicketListHandler(searchText, page);
  }, []);

  const pageChangeHandler = (data) => {
    setPage(data);
    getTicketListHandler(searchText, data);
  };

  const searchHandler = (value) => {
    setSearchText(value);
    getTicketListHandler(value, page);
  };

  const getTicketListHandler = async (search, page) => {
    try {
      setLoading(true);
      const { count, data, message, permission, success, totalPage } =
        await getTicketDataApiHandler(search, page);
      if (success) {
        setDataCount(count);
        setTicketList(data);
      } else {
        setDataCount(0);
        setTicketList([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="cardContainer">
      <Box p={"0 5px 10px"}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search
            searchHandler={(event) => searchHandler(event.target.value)}
          />
          <Pagination
            pageNumber={page}
            count={dataCount}
            pageChangeHandler={pageChangeHandler}
          />
        </Stack>
      </Box>
      <Divider />
      <Box p={"10px 5px"}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            variant="text"
            size="small"
            startIcon={<BsFilterRight />}
            buttonName="Filter"
          />
        </Stack>
      </Box>
      {/* <Divider /> */}
      <TableContainer component={Paper} className="tableContainer">
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiTicketTable-root">
              <TableCell>Sr No.</TableCell>
              <TableCell>Assignee Name</TableCell>
              <TableCell>subject</TableCell>
              <TableCell>message</TableCell>
              <TableCell>Ticket Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (
                <TableSkeleton rowsNum={5} colsNum={7} />
              ) : (
                ticketList && ticketList.length > 0 ? (
                  ticketList.map((response, i) => {
                    const {
                      assigned_to,
                      attachment_files,
                      created_by,
                      id,
                      message,
                      status,
                      subject,
                      ticket_number,
                      visitor_user,
                      updated_at,
                    } = response;
                    return (
                      <TableRow
                        key={i}
                        className="MuiTicketTable-root"
                        onClick={() => navigate("/conversations/details")}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {assigned_to && assigned_to.first_name && assigned_to.last_name
                            ? assigned_to.first_name + " " + assigned_to.last_name
                            : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Stack>
                            <Typography
                              fontSize={"11px"}
                              variant="primary"
                              fontWeight={"400"}
                            >
                              {subject}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {message ? message : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket_number ? ticket_number : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {status ? status : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {moment(updated_at).format("DD-MM-YYYY - HH:mm:ss")}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="6"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                )
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Tickets;
