import React from 'react'
import { Box, Grid, Stack, Switch, Typography } from '@mui/material'
import {  } from '../../helper/Theme';


const Notification = () => {


    return (
        <Box className='cardContainer' sx={{ p: { xs: 2, sm: 3, md: 5 } }}>
            <Grid container spacing={1} maxWidth={'400px'}>
                <Grid item xs={12}>
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>WhatsApp Update</Typography>
                        <Switch defaultChecked color="secondary" />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>SMS Update</Typography>
                        <Switch defaultChecked color="secondary" />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Notification