import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import {
  BsFilterRight,
  LuTrash2,
  MdOutlineEdit,
  MdOutlineMoreVert,
  RiLockPasswordLine,
} from "../../helper/Icons";
import { Button, Modal } from "../commons";
import { Pagination, Search } from "../commons/Table";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { getAllUserApiDataWithPagination } from "../../Service/User/User.service";
import TableSkeleton from "../commons/TableSkeleton";
import NoRecordFound from "../commons/NoRecordFound";

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [userList, setUserList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    data: null,
    isEdit: "",
    head: "",
    para: "",
  });

  const getUserListHandler = async (searchText, page) => {
    try {
      setLoading(true);
      const { count, data, message, permission, success, totalPage } =
        await getAllUserApiDataWithPagination(searchText, page);
      if (success) {
        setDataCount(count);
        setUserList(data);
      } else {
        setDataCount(0);
        setUserList([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserListHandler(searchText, page);
  }, []);

  const callBackHandler = () => {
    getUserListHandler(searchText, page);
  };

  const searchHandler = (value) => {
    setSearchText(value);
    getUserListHandler(value, page);
  };

  const pageChangeHandler = (value) => {
    setPage(value);
    getUserListHandler(searchText, value);
  };

  return (
    <Box className="cardContainer">
      <Box p={"0 5px 10px"}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search
            searchHandler={(event) => searchHandler(event.target.value)}
          />
          <Pagination
            pageNumber={page}
            count={dataCount}
            pageChangeHandler={pageChangeHandler}
          />
        </Stack>
      </Box>
      <Divider />
      <Box p={"10px 5px"}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            variant="text"
            size="small"
            startIcon={<BsFilterRight />}
            buttonName="Filter"
          />
          <Button
            buttonName="Create User"
            color="white"
            size="small"
            onClick={() =>
              setIsModalOpen({ open: true, currentComponent: "addUser" })
            }
          />
        </Stack>
      </Box>
      <Divider />
      <TableContainer component={Paper} className="tableContainer">
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiUserTable-root">
              <TableCell>Sr No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton rowsNum={5} colsNum={6} />
            ) : userList && userList.length > 0 ? (
              userList.map((response, i) => {
                const {
                  address,
                  email,
                  first_name,
                  group_name,
                  id,
                  groups_list,
                  is_active,
                  is_staff,
                  is_superuser,
                  last_name,
                  phone,
                  profile_image,
                  user_permissions,
                } = response;
                return (
                  <TableRow
                    className="MuiUserTable-root"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {first_name && last_name
                        ? first_name + " " + last_name
                        : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {email ? email : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {address ? address : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {group_name ? group_name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Stack alignItems={"flex-end"}>
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger className="chatDropDown">
                            <MdOutlineMoreVert
                              style={{ fontSize: "24px", cursor: "pointer" }}
                            />
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content
                            className="chatDropDownContent"
                            align="end"
                          >
                            <DropdownMenu.Item
                              className="chatDropDownMenu"
                              onClick={() =>
                                setIsModalOpen({
                                  open: true,
                                  currentComponent: "addUser",
                                  isEdit: true,
                                  data: {
                                    userId: id,
                                    data:response
                                  },
                                })
                              }
                            >
                              <Stack
                                flexDirection={"row"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <MdOutlineEdit />
                                <Box>Edit</Box>
                              </Stack>
                            </DropdownMenu.Item>

                            <DropdownMenu.Item
                              className="chatDropDownMenu"
                              onClick={() =>
                                setIsModalOpen({
                                  open: true,
                                  currentComponent: "delete",
                                  head: "Delete User",
                                  para: "Are you sure you want to Delete this User?",
                                  data: {
                                    DeleteId: id,
                                    Module: "USER",
                                  },
                                })
                              }
                            >
                              <Stack
                                flexDirection={"row"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <LuTrash2 />
                                <Box>Delete</Box>
                              </Stack>
                            </DropdownMenu.Item>

                            <DropdownMenu.Item
                              className="chatDropDownMenu"
                              onClick={() =>
                                setIsModalOpen({
                                  open: true,
                                  currentComponent: "changePassword",
                                })
                              }
                            >
                              <Stack
                                flexDirection={"row"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <RiLockPasswordLine />
                                <Box>Password</Box>
                              </Stack>
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        callBack={callBackHandler}
      />
    </Box>
  );
};

export default Users;
