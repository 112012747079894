import { Box, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Button, Modal, Select } from '../commons'
import {  } from '../../helper/Theme';
import { IoClose, MdOutlineEmail, FiPhone, TbWorld, RiOrganizationChart, BiSolidPlusSquare } from '../../helper/Icons';
import { Link } from 'react-router-dom';

const Organization = () => {

    const [orgValue, setOrgValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        head: "",
        para: "",
    });
    const handleChange = (e) => { setOrgValue(e.target.value) };

    const organizationList = [
        { value: 'org1', name: 'Org 1' },
        { value: 'org2', name: 'Org 2' },
    ]
    return (
        <Box>
            <Stack p={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                    <RiOrganizationChart style={{ fontSize: '20px' }} />
                    <Typography fontWeight={"600"} lineHeight={"16px"}>Organization</Typography>
                </Stack>
                {!orgValue && <BiSolidPlusSquare style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => setIsModalOpen({ open: true, currentComponent: "createOrganization" })} />}
            </Stack>
            <Divider />
            <Stack gap={1} py={2} px={1}>
                {
                    !orgValue &&
                    <Stack flexDirection={'row'} gap={1} justifyContent={'center'}>
                        <Select selectHead='Select Organization' selectList={organizationList} value={orgValue} onChange={handleChange} />
                    </Stack>
                }
                {
                    orgValue &&
                    <Box>
                        <Stack flexDirection={'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'}>
                            <Stack flexDirection={'row'} gap={2} alignItems={'flex-start'}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', borderRadius: '5px', backgroundColor: 'secondary.main', fontSize: '20px', lineHeight: '22px', color: '#fff', fontWeight: '600' }}>T</Box>
                                <Stack gap={'5px'}>
                                    <Typography fontWeight={600} mt={'5px'} mb={'3px'}>Test Organization</Typography>
                                    <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                        <MdOutlineEmail />
                                        <Typography fontSize={"14px"}>john.wich@gmail.com</Typography>
                                    </Stack>
                                    <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                        <FiPhone />
                                        <Typography fontSize={"14px"}>+1234567890</Typography>
                                    </Stack>
                                    <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                        <TbWorld />
                                        <Typography fontSize={"14px"}>www.google.com</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Box onClick={() => setOrgValue('')}><IoClose style={{ fontSize: '22px', cursor: 'pointer' }} /></Box>
                        </Stack>
                    </Box>
                }
            </Stack>
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "" })
                } />
        </Box>
    )
}

export default Organization