import React from 'react'
import { Stack, Typography } from '@mui/material'
import { MdOutlineEmail, FiPhone, IoBriefcaseOutline } from '../../../helper/Icons';

const UserInfo = () => {
    return (
        <>
            <Stack p={2} gap={2}>
                <Stack flexDirection={'row'} gap={2}>
                    <MdOutlineEmail style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>Email</Typography>
                        <Typography fontSize={"16px"}>Test@gmail.com</Typography>
                    </Stack>
                </Stack>
                <Stack flexDirection={'row'} gap={2}>
                    <FiPhone style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>Phone number</Typography>
                        <Typography fontSize={"16px"}>1234567890</Typography>
                    </Stack>
                </Stack>
                <Stack flexDirection={'row'} gap={2}>
                    <IoBriefcaseOutline style={{ fontSize: '20px' }} />
                    <Stack alignItems={'start'}>
                        <Typography fontSize={"13px"} fontWeight={'300'}>Job Title</Typography>
                        <Typography fontSize={"16px"}>Job Title</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default UserInfo