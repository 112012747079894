import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Grid,
  Typography,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Button, FileUpload, Input } from "../../commons";
import { CloseIcon } from "../../../helper/Icons";
import { useFormik } from "formik";
import { createTicketIv } from "../../../helper/intialValues";
import { createTicketValidation } from "../../../helper/Validation";
import {
  getAllUserApiData,
  getAllVisitorApiData,
} from "../../../Service/User/User.service";
import { createTicketApiHandler } from "../../../Service/Ticket/Ticket.service";
import { errorNotification, successNotification } from "../../../helper/Notification";

const ComposeTicket = ({ handleClose,callBack }) => {
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [visiterLoader, setVisiterLoader] = useState(false);
  const [vistiorData, setVistiorData] = useState([]);
  const [selectVistor, setSelectVistor] = useState("");
  const [userData, setUserData] = useState([]);
  const [selectUser, setSelectUser] = useState("");
  const [files, setFiles] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);

  const createTicketHandler = async() => {
    try{
      setLoading(true);
      const formData = new FormData();
      formData.append("subject", formik.values.subject);
      formData.append("message", formik.values.message);
      formData.append("assigned_to", formik.values.assigned_to);
      formData.append("visitor_user", formik.values.visitor_user);
      formData.append("attachment_files", files.length >0 ? files :[]);
      const {data,message,success} = await createTicketApiHandler(formData);
      if(success){
        successNotification(message);
        handleClose();
        callBack();
      }else{
        errorNotification(message);
      }
    }catch(e){
      console.error(e);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUserDataHandler();
    getAllVistorDataHandler();
  }, []);

  const getAllUserDataHandler = async (userId) => {
    try {
      setUserLoading(true);
      const { count, data, message, success, totalPage, permission } =
        await getAllUserApiData();
      if (success) {
        setUserData(data);
      } else {
        setUserData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setUserLoading(false);
    }
  };

  const getAllVistorDataHandler = async () => {
    try {
      setVisiterLoader(true);
      const { count, data, message, permission, success, totalPage } =
        await getAllVisitorApiData();
      if (success) {
        setVistiorData(data);
      } else {
        setVistiorData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setVisiterLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: createTicketIv,
    validationSchema: createTicketValidation,
    onSubmit: createTicketHandler,
  });

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Ticket
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: "600px" }}>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Input
              labelinput="Subject"
              name="subject"
              formik={formik}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput="Message"
              name="message"
              multiline
              formik={formik}
              rows={3}
              inputProps={{ maxLength: 2000 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUpload
              FileType="Image"
              acceptFormat="image/*"
              files={files}
              setFiles={setFiles}
              setDeleteImage={setDeleteImage}
              deleteImage={deleteImage}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              value={selectVistor}
              displayEmpty
              style={{ width: "100%" }}
              onChange={(event) => [
                setSelectVistor(event.target.value),
                formik.setFieldValue("visitor_user", event.target.value),
              ]}
            >
              {vistiorData && vistiorData.length > 0 ? (
                vistiorData.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data.id}>
                      {data.visitor_name}
                    </MenuItem>
                  );
                })
              ) : (
                <Typography>No data found</Typography>
              )}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Select
              value={selectUser}
              displayEmpty
              fullWidth
              onChange={(event) => [
                setSelectUser(event.target.value),
                formik.setFieldValue("assigned_to", event.target.value),
              ]}
            >
              {userData && userData.length > 0 ? (
                userData.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data.id}>
                      {data.first_name} {data.last_name}
                    </MenuItem>
                  );
                })
              ) : (
                <Typography>No data found</Typography>
              )}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Create"
          size="small"
          loading={loading}
          disabled={loading}
          onClick={() => formik.handleSubmit()}
        />
      </DialogActions>
    </>
  );
};

export default ComposeTicket;