import { RxDashboard, PiChatsTeardropBold, LuInbox, TbReportAnalytics, LuContact2, CgUserlane, TbHelpSquareRounded, TbChartDots3, IoSettingsOutline } from '../helper/Icons'
import { LuBrainCircuit } from "react-icons/lu";
import { FaSlackHash } from "react-icons/fa";
import { FaHashnode } from "react-icons/fa6";



export const SidebarList = [
    {
        name: 'Dashboard',
        icon: <RxDashboard style={{ fontSize: '20px', strokeWidth: '0.2px', color: '#fff', cursor: 'pointer' }} />,
        activeIcon: <RxDashboard style={{ fontSize: '20px', strokeWidth: '0.2px', color: '#000', cursor: 'pointer' }} />,
        path: '/dashboard',
        SUBMENU: false,
    },
    {
        name: 'Conversations',
        icon: <LuInbox style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#fff', cursor: 'pointer' }} />,
        activeIcon: <LuInbox style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#000', cursor: 'pointer' }} />,
        path: '/conversations',
        SUBMENU: false,
    },
    {
        name: 'Live Support',
        icon: <PiChatsTeardropBold style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#fff', cursor: 'pointer' }} />,
        activeIcon: <PiChatsTeardropBold style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#000', cursor: 'pointer' }} />,
        path: '/livesupport',
        SUBMENU: false,
    },
    {
        name: 'Contacts',
        icon: <LuContact2 style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#fff', cursor: 'pointer' }} />,
        activeIcon: <LuContact2 style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#000', cursor: 'pointer' }} />,
        path: '/contacts',
        SUBMENU: false,
    },
    {
        name: 'Analytics and Reporting',
        icon: <TbReportAnalytics style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#fff', cursor: 'pointer' }} />,
        activeIcon: <TbReportAnalytics style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#000', cursor: 'pointer' }} />,
        path: '/report',
        SUBMENU: false,
    },
    {
        name: 'User Management',
        icon: <CgUserlane style={{ fontSize: '20px', strokeWidth: '0.1px', color: '#fff', cursor: 'pointer' }} />,
        activeIcon: <CgUserlane style={{ fontSize: '20px', strokeWidth: '0.1px', color: '#000', cursor: 'pointer' }} />,
        path: '/usermanagement',
        SUBMENU: false,
    },
    {
        name: 'Settings',
        icon: <IoSettingsOutline style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#fff', cursor: 'pointer' }} />,
        activeIcon: <IoSettingsOutline style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#000', cursor: 'pointer' }} />,
        path: '/settings',
        SUBMENU: false,
    },
    {
        name: 'Content',
        icon: <FaHashnode style={{ fontSize: '20px', strokeWidth: '0.2px', color: '#fff', cursor: 'pointer' }} />,
        activeIcon: <FaHashnode style={{ fontSize: '20px', strokeWidth: '0.2px', color: '#000', cursor: 'pointer' }} />,
        SUBMENU: true,
        subMenus: [
            {
                name: 'Intent',
                icon: <FaSlackHash style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#fff', cursor: 'pointer' }} />,
                activeIcon: <FaSlackHash style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#000', cursor: 'pointer' }} />,
                path: '/intent',
                SUBMENU: false,
            },
            {
                name: 'Knowledge',
                icon: <LuBrainCircuit style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#fff', cursor: 'pointer' }} />,
                activeIcon: <LuBrainCircuit style={{ fontSize: '20px', strokeWidth: '2.2px', color: '#000', cursor: 'pointer' }} />,
                path: '/knowledge',
                SUBMENU: false,
            },
        ]
    }
]

export const genderList = [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
]

// Report & Analytics ============================

export const chatOptions = {
    title: '',
    curveType: "function",
    legend: 'none',
    backgroundColor: 'transparent',
    vAxis: {
        minValue: 0,
        baseline: 0,
    },
    chartArea: { width: '90%', height: '80%' },
    series: {
        0: {
            color: '#f7cc49',
        },
        1: {
            color: '#f7cc49',
        },
    },
}

export const chatData = [
    ['Category', 'Total Chats', 'Missed Chats'],
    ['JUL 18', 160, 80],
    ['JUL 19', 140, 10],
    ['JUL 20', 200, 100],
    ['JUL 21', 180, 80],
    ['JUL 22', 120, 20],
    ['JUL 23', 140, 60],
    ['JUL 24', 180, 70],
]

export const ticketOptions = {
    title: '',
    legend: 'none',
    backgroundColor: 'transparent',
    chartArea: { width: '90%', height: '80%' },
    series: {
        0: {
            color: '#f7cc49',
        },
        1: {
            color: '#f7cc49',
        },
        2: {
            color: '#f7cc49',
        },
    },
}

export const ticketData = [
    ["Year", "Live Visitor"],
    ['JUL 18', 10],
    ['JUL 19', 20],
    ['JUL 20', 30],
    ['JUL 21', 20],
    ['JUL 22', 40],
    ['JUL 23', 40],
    ['JUL 24', 10],
]

export const liveChatOptions = {
    title: '',
    curveType: "function",
    legend: 'none',
    backgroundColor: 'transparent',
    vAxis: {
        minValue: 0,
        baseline: 0,
    },
    chartArea: { width: '90%', height: '80%' },
    series: {
        0: {
            color: '#f7cc49',
        },

    },
}

export const liveChatData = [
    ['Category', 'Live Visitor', { role: "style" }],
    ['Sunday', 30, '#f7cc49'],
    ['Monday', 190, '#f7cc49'],
    ['Tuesday', 50, '#f7cc49'],
    ['Wednesday', 180, '#f7cc49'],
    ['Thursday', 10, '#f7cc49'],
    ['Friday', 140, '#f7cc49'],
    ['Saturday', 80, '#f7cc49'],
]

export const toptrendsOptions = {
    title: '',
    curveType: "function",
    legend: 'none',
    backgroundColor: 'transparent',
    vAxis: {
        minValue: 0,
        baseline: 0,
    },
    chartArea: { width: '90%', height: '80%' },
    series: {
        0: {
            color: '#f7cc49',
        },

    },
}

export const toptrendsData = [
    ['Category', 'Trending Topics'],
    ['JUL 18', 30],
    ['JUL 19', 120],
    ['JUL 20', 50],
    ['JUL 21', 180],
    ['JUL 22', 100],
    ['JUL 24', 80],
]

// Report & Analytics ============================
