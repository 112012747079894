import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#ffc107',
    },
    secondary: {
      main: '#c1c1c1',
    },
    black: {
      main: '#000000',
    },
    white: {
      main: '#FFFFFF',
    },
    gray: {
      dark: '#383838',
      main: '#6A6A6A',
      secondary: '#2B2B2B',
      // head: '#010425',
      // para: '#292929',
      text: '#858585',
    },
    background: {
      main: '#fff'
    },
    border: {
      main: '#0000001f',
      secondary: '#0000001f',
    },

  },
  components: {
    // button -----------
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: 'capitalize !important',
          fontSize: '16px !important',
          lineHeight: '1 !important',
          fontWeight: '500',
          color: '#000',
          padding: '14px 20px',
          width: 'fit-content',
          minWidth: 'unset',
          whiteSpace: 'nowrap',
          boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            padding: '13px 15px',
          },
          '& .MuiButton-startIcon svg': {
            '@media (max-width: 600px)': {
              fontSize: '19px !important',
            },
          }
        },
        containedWhite: {
          color: '#000',
          boxShadow: 'unset',
          border: '1px solid #0000001f'
        },
        sizeSmall: {
          padding: '12px 15px',
          fontSize: '14px !important',
        },
        containedGreen: {
          padding: '10px 20px',
        },
        text: {
          boxShadow: 'unset !important',
          color: '#000',
        }
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '7px',
        }
      }
    },
    // button -----------
    // input ------------
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            input: {
              fontSize: '16px',
              fontWeight: '400',
              borderRadius: '10px',
              padding: '12px 20px',
              color: '#000',
              background: '#fff',
              boxShadow: '0px 0px 4px 0px #407BFF20',
              '@media (max-width: 600px)': {
                fontSize: '14px !important',
                padding: '12px 13px',

              },
            },
            fieldset: {
              // border: 'unset !important',
            }
          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: '#000',
          },
          '& .MuiInputBase-adornedStart ': {
            paddingLeft: '10px',
            input: {
              padding: '9px 14px 9px 0',
            },
          }
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '5px',
        },
        root: {
          '& .MuiInputBase-input': {
            boxShadow: 'unset !important',
          }
        }
      }
    },
    // input ------------
    // select ------------
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: '10px',
          fontSize: '16px',
          fontWeight: '400',
          padding: '12px 20px',
          minHeight: 'unset',
          color: '#000',
          background: '#fff',
          boxShadow: 'unset',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            padding: '10px 15px',
          },
          '& .MuiInputBase-root:hover': {
            // border: '1px solid #454545 !important',
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#000',
          },
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            minHeight: '35px !important',
          },
        }
      }
    },
    // select ------------
    // table -----------
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root': {
            '& .MuiTableRow-root': {
            },
            '& .MuiTableCell-root': {
              borderTop: '1px solid #0000001f',
              fontWeight: '500',
              textTransform: 'capitalize',
              color: '#000',

            }
          },
          '& .MuiTableCell-root': {
            padding: '12px 15px',
            fontSize: '15px',
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #0000001f',
            // color: '#4E4E4E',
            textAlign: 'center !important',
            // '&:nth-of-type(2)': {
            //   textAlign: 'start !important',
            // },
            '&:first-of-type': {
              textAlign: 'start !important',
            },
            '&:last-child': {
              // textAlign: 'end !important',
            },
          },
          // Media query for smaller screens
          '@media (max-width: 600px)': {
            '& .MuiTableCell-root': {
              fontSize: '14px',
              padding: '10px 10px',

            },
          },
          '& .MuiTableBody-root': {
            '& .MuiTableRow-root:hover': {
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              background: '#ffc10710'
            },
          },
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '15px !important',
          height: '25px',
          fontSize: '14px',
          '@media (max-width: 600px)': {
            height: '23px',
            fontSize: '12px',
          },
          '& .MuiSvgIcon-root ': {
            fontSize: '18px',
          }
        }
      }
    },
    // table -----------
    // modal -----------
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: '600',
        }
      }
    },
    // modal -----------
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            minHeight: '40px',
            textTransform: 'capitalize',
            alignItems: 'flex-start'
          },
          '& .Mui-selected ': {
            background: '#eef4f6',
          },
          '& .MuiTabs-indicator': {
            left: 0,
            right: 'unset',
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: '9999',
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '20px !important'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          marginBottom: 0,
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#00000080'
        }
      }
    }
  },
});


export const getChatStatus = (value) => {
  const status = {
    false: {
      title: "Off",
      styles: {
        backgroundColor: "#FF000030",
        color: "#FF0000",
      },
    },
    true: {
      title: "On",
      styles: {
        backgroundColor: "#2e7d3230",
        color: "#2e7d32",
      },
    },

  };
  return status[value];
};

export const getUserLiveStatus = (value) => {
  const status = {
    false: {
      title: "Offline",
      styles: {
        backgroundColor: "#FF000030",
        color: "#FF0000",
      },
    },
    true: {
      title: "Online",
      styles: {
        backgroundColor: "#2e7d3230",
        color: "#2e7d32",
      },
    },

  };
  return status[value];
};