import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  Select,
  DialogTitle,
  Typography,
  MenuItem,
  FormHelperText,
  Grid,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "../../commons";
import { useFormik } from "formik";
import { createUserIv } from "../../../helper/intialValues";
import { createUserValidation, updateUserValidation } from "../../../helper/Validation";
import { createUserApiHandler, updateUserByAdminApiHandler } from "../../../Service/User/User.service";
import {
  errorNotification,
  successNotification,
} from "../../../helper/Notification";
import { getRoleListApiHandler } from "../../../Service/Role/Role.service";

const AddUsers = ({ handleClose, modalOpen, callBack }) => {
  console.log("modalOpen", modalOpen);
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [selectRole, setSelectRole] = useState("");

  useEffect(() => {
    getRoleListHandler();
  }, []);

  useEffect(() => {
    if (modalOpen.isEdit) {
      getUserDetailsHandler(modalOpen?.data?.data);
    }
  }, []);

  const getUserDetailsHandler = (userData) => {
    formik.setValues({
      ...formik.values,
      email: userData?.email,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      phone: userData?.phone,
      address: userData?.address,
      groups: userData?.group_id,
      organization: userData?.organization,
      tags: userData?.tags,
    });
    setSelectRole(userData?.group_id);
  };

  const getRoleListHandler = async () => {
    try {
      setLoading(true);
      const { data, success, message, count, permission, totalPage } =
        await getRoleListApiHandler();
      if (success) {
        setRoleList(data);
      } else {
        setRoleList([]);
        errorNotification(message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const createUserHandler = async () => {
    try {
      setLoading(true);
      const { data, message, success } = modalOpen?.isEdit ? await updateUserByAdminApiHandler(modalOpen?.data?.userId,formik.values) : await createUserApiHandler(
        formik.values
      );
      if (success) {
        successNotification(message);
        handleClose();
        callBack();
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: createUserIv,
    validationSchema: modalOpen?.isEdit ? updateUserValidation :createUserValidation,
    onSubmit: createUserHandler,
  });
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? "Edit" : "Create"} User
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="First Name"
              name="first_name"
              formik={formik}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="Last Name"
              name="last_name"
              formik={formik}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="Email"
              name="email"
              disabled={modalOpen.isEdit}
              formik={formik}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="Phone"
              name="phone"
              formik={formik}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput="Address"
              multiline
              rows={2}
              name="address"
              formik={formik}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          {!modalOpen.isEdit && (
            <>
            <Grid item xs={12} sm={6}>
              <Input
                labelinput="Password"
                passwordinput
                name="password"
                formik={formik}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="Confirm Password"
              passwordinput
              name="confirm_password"
              formik={formik}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
            </>
          )}
          <Grid item xs={12} sm={12}>
            <Typography fontSize={"12px"} fontWeight={600} mb={"2px"}>
              {"Role"}
            </Typography>
            <Select
              value={selectRole}
              displayEmpty
              fullWidth
              error={formik.errors.groups}
              onChange={(event) => [
                setSelectRole(event.target.value),
                formik.setFieldValue("groups", event.target.value),
              ]}
            >
              {roleList && roleList.length > 0 ? (
                roleList.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })
              ) : (
                <Typography>No data found</Typography>
              )}
            </Select>
            {formik.errors.groups && (
              <FormHelperText>{formik.errors.groups}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName={modalOpen.isEdit ? "Save" : "Create"}
          size="small"
          loading={loading}
          disabled={loading}
          onClick={() => formik.handleSubmit()}
        />
      </DialogActions>
    </>
  );
};

export default AddUsers;
