import React from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Box, Stack, Typography, Chip, Divider } from '@mui/material';
import { getChatStatus, } from '../../helper/Theme';
import { BsFilterRight, CiStar } from '../../helper/Icons';
import { Pagination, Search } from '../commons/Table';
import { useNavigate } from 'react-router-dom';
import { Button } from '../commons';

const Chats = () => {
    const navigate = useNavigate();

    var status = getChatStatus(true);

    return (
        <Box className='cardContainer' >
            <Box p={'0 5px 10px'}>
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Search />
                    <Pagination />
                </Stack>
            </Box>
            <Divider />
            <Box p={'10px 5px'}>
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Button variant="text" size='small' startIcon={<BsFilterRight />} buttonName='Filter' />
                </Stack>
            </Box>
            <TableContainer component={Paper} className='tableContainer'>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr No.</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>Total Messages</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Agents</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Updated</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[1, 1, 1, 1].map((data, i) => {
                            return (
                                <TableRow key={i} onClick={() => navigate('/conversations/details')}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                    <TableCell component="th" scope="row">{i + 1}</TableCell>
                                    <TableCell component="th" scope="row">
                                        <Stack>
                                            <Typography fontSize={'13px'} fontWeight={'500'}>John Doe</Typography>
                                            <Typography fontSize={'11px'} variant='primary' fontWeight={'400'}>johndoe@gmail.com</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell component="th" scope="row">1</TableCell>
                                    <TableCell component="th" scope="row">1 seconds</TableCell>
                                    <TableCell component="th" scope="row">Anna</TableCell>
                                    <TableCell component="th" scope="row">Facebook</TableCell>
                                    <TableCell component="th" scope="row">
                                        <Stack flexDirection={'row'} gap={'3px'} alignItems={'center'} justifyContent={'center'}>
                                            <div>4.5</div>
                                            <CiStar style={{ fontSize: '18px' }} />
                                        </Stack>
                                    </TableCell>
                                    <TableCell component="th" scope="row" onClick={(e) => { e.stopPropagation() }}>
                                        <Chip label={status?.title} sx={status?.styles} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">Jun 29, 2022 at <br />01:14 PM</TableCell>
                                </TableRow>
                            )
                        })}
                        {/* <TableRow>
                            <TableCell sx={{ color: "silver", textAlign: "center", paddingTop: "90px", borderBottom: "none", fontSize: "30px", }} colSpan="6">
                                No records to display
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default Chats