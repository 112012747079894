import { Stack } from '@mui/material'
import React from 'react'

const MenuButton = (props) => {
    const { icon, onClick } = props
    return (
        <Stack onClick={onClick} sx={{ backgroundColor: 'background.main', display: { xs: 'flex' }, border: '1px solid #0000001f', cursor: 'pointer', boxShadow: '2px 2px 25px 0px #00000026', width: { xs: '35px', sm: '42px' }, height: { xs: '35px', sm: '42px' }, borderRadius: '8px' }} justifyContent={'center'} alignItems={'center'}>
            {icon}
        </Stack>
    )
}

export default MenuButton